import { Routes, Route, Navigate } from 'react-router-dom';
import { CONST_ROUTER } from './shared/constant/common';
import DashBoard from './pages/DashBoard';
import PublicRoutes from './components/PublicRoutes';
import NotFoundPage from './pages/NotFoundPage';
import ForgetPass from './pages/ForgetPass';
import ManagementCustomer from './pages/ManagementCustomer';
import DriverManagement from './pages/DriverManagement';
import AddDriver from './pages/DriverManagement/AddDriver';
import AddCustomer from './pages/ManagementCustomer/AddCustomer';
import TabsDetailCustomer from './pages/ManagementCustomer/TabsDetailCustomer';
import VehicleClass from './pages/VehicleClass';
import AddVeihicleClass from './pages/VehicleClass/AddVehicleClass';
import { UpdatePasswordProfile } from './pages/UpdatePassWord';
import ProfileUser from './pages/Profile';
import { DriverActive } from './pages/DriverActive';
import AccountManagement from './pages/AccountManagement';
import AddAndEditAccount from './pages/AccountManagement/AddAndEditAccount';
import { DashBoardChart } from './pages/DashBoardChart';
import TabsDriverDetail from './pages/DriverManagement/TabsDriverDetail';
import SignInSide from './pages/Login';
import ProtectedRoutes from './components/ProtectedRoutes';
import TripManagement from './pages/TripManagement';
import RevenueStatistics from './pages/Statistics/RevenueStatistics';
import TripStatistics from './pages/Statistics/TripStatistics';
import EvaluationStatistics from './pages/Statistics/EvaluationStatistics';
import PaymentSetting from './pages/SystemConfiguration/PaymentSetting';
import Notification from './pages/Notification';
import SystemPriceSetting from './pages/SystemConfiguration/SystemPrice';
import AddAreaPrice from './pages/SystemConfiguration/SystemPrice/AddAreaPrice';
import { ROLE_ADMIN, ROLE_EMPLOYEE } from './shared/config/setting';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivaceClause from './pages/PrivaceClause';
import TabsTripDetail from './pages/TripManagement/TabsTripDetail';
import DeleteAccountPage from './pages/RequestDeleteAccount';
import Offer from './pages/Offer';
import { AddOffer } from './pages/Offer/AddOffer';
import { PaymentPolicy } from './pages/PaymentPolicy';
import TermsOfUse from './pages/TermsOfUserDriver';
import { IS_REQUEST_DELETE_DRIVER, IS_REQUEST_DELETE_USER } from './shared/constant/text';
import AddTrip from './pages/TripManagement/AddTrip';

export default function MainRoutes() {
  const routesAdmin = [
    //Vehicle class
    { path: CONST_ROUTER.VEHICLE_CLASS, element: <VehicleClass /> },
    { path: CONST_ROUTER.VEHICLE_CLASS_ADD, element: <AddVeihicleClass /> },
    { path: CONST_ROUTER.DETAIL_VEHICLE, element: <AddVeihicleClass /> },
    // Account
    { path: CONST_ROUTER.ACCOUNT, element: <AccountManagement /> },
    { path: CONST_ROUTER.ADD_ACCOUNT, element: <AddAndEditAccount /> },
    { path: CONST_ROUTER.EDIT_ACCOUNT_ROUTER, element: <AddAndEditAccount /> },
    // Setting system
    { path: CONST_ROUTER.PAYMENT_SETTING, element: <PaymentSetting /> },
    { path: CONST_ROUTER.SYSTEM_PRICE_SETTING, element: <SystemPriceSetting /> },
    { path: CONST_ROUTER.ADD_AREA_PRICE, element: <AddAreaPrice /> },
    { path: CONST_ROUTER.AREA_PRICE_DETAIL, element: <AddAreaPrice /> },
    // Statistics
    { path: CONST_ROUTER.REVENUE, element: <RevenueStatistics /> },
    { path: CONST_ROUTER.TRIP_STATISTICS, element: <TripStatistics /> },
    { path: CONST_ROUTER.EVALUATION_STATISTICS, element: <EvaluationStatistics /> },
    //Offer
    { path: CONST_ROUTER.OFFER, element: <Offer /> },
    { path: CONST_ROUTER.ADD_OFFER, element: <AddOffer /> },
    { path: CONST_ROUTER.DETAIL_OFFER_ITEM, element: <AddOffer /> },
  ];

  const routesPublic = [
    { path: CONST_ROUTER.DASH_BOARD_CHART, element: <DashBoardChart /> },
    //Profile
    { path: CONST_ROUTER.UPDATE_PASS, element: <UpdatePasswordProfile /> },
    // Driver
    { path: CONST_ROUTER.DRIVER_MANAGEMENT, element: <DriverManagement /> },
    { path: CONST_ROUTER.DRIVER_DETAIL, element: <TabsDriverDetail /> },
    { path: CONST_ROUTER.ADD_DRIVER, element: <AddDriver /> },
    { path: CONST_ROUTER.DRIVER_ACTIVE, element: <DriverActive /> },
    //Customer
    { path: CONST_ROUTER.MANAGEMENT_CUSTOMER, element: <ManagementCustomer /> },
    { path: CONST_ROUTER.ADD_CUSTOMER, element: <AddCustomer /> },
    { path: CONST_ROUTER.DETAIL_CUSTOMER, element: <TabsDetailCustomer /> },
    { path: CONST_ROUTER.PROFILE_USER, element: <ProfileUser /> },
    //Trip
    { path: CONST_ROUTER.ADD_TRIP, element: <AddTrip /> },
    { path: CONST_ROUTER.TRIP_MANAGEMENT, element: <TripManagement /> },
    { path: CONST_ROUTER.TRIP_DETAIL1, element: <TabsTripDetail /> },
    { path: CONST_ROUTER.TRIP_DETAIL2, element: <TabsTripDetail /> },
    //Notification
    { path: CONST_ROUTER.NOTIFICATION, element: <Notification /> },
  ];

  return (
    <Routes>
      <Route path={CONST_ROUTER.DASHBOARD} element={<ProtectedRoutes />}>
        <Route path={CONST_ROUTER.DASHBOARD} element={<DashBoard />}>
          {/* ROLE ADMIN */}
          <Route path={CONST_ROUTER.DASHBOARD} element={<ProtectedRoutes roleRequired={[ROLE_ADMIN]} />}>
            <Route index element={<Navigate to={CONST_ROUTER.DASH_BOARD_CHART} replace />} />
            {routesAdmin.map((route: any, index) => {
              return <Route key={index} path={route.path} element={route.element} />;
            })}
          </Route>
          {/* ALL ROLE */}
          <Route path={CONST_ROUTER.DASHBOARD} element={<ProtectedRoutes roleRequired={[ROLE_ADMIN, ROLE_EMPLOYEE]} />}>
            {routesPublic.map((route: any, index) => {
              return <Route key={index} path={route.path} element={route.element} />;
            })}
          </Route>
        </Route>
      </Route>

      {/* Public Route Login */}
      <Route path={CONST_ROUTER.LOGIN} element={<PublicRoutes />}>
        <Route path={CONST_ROUTER.LOGIN} element={<SignInSide />} />
      </Route>
      <Route path={CONST_ROUTER.FORGET_PASS} element={<ForgetPass />} />
      <Route path="*" element={<NotFoundPage />} />
      {/* Public Route Privacy */}
      {/*User*/}
      <Route path={CONST_ROUTER.USER_POLICY.PRIVACY} element={<PrivacyPolicy />} />
      <Route path={CONST_ROUTER.USER_POLICY.CLAUSE} element={<PrivaceClause />} />
      {/*Driver*/}
      <Route path={CONST_ROUTER.DRIVER_POLICY.PRIVACY} element={<PrivacyPolicy isUser={false} />} />
      <Route path={CONST_ROUTER.DRIVER_POLICY.CLAUSE} element={<TermsOfUse />} />

      <Route path={CONST_ROUTER.PAYMENT_POLICY} element={<PaymentPolicy />} />

      {/* Public Route Request Delete Account */}
      <Route
        path={CONST_ROUTER.REQUEST_DELETE_ACCOUNT}
        element={<DeleteAccountPage isRoleDelete={IS_REQUEST_DELETE_USER} />} // user
      />
      <Route
        path={CONST_ROUTER.REQUEST_DELETE_DRIVER}
        element={<DeleteAccountPage isRoleDelete={IS_REQUEST_DELETE_DRIVER} />} // driver
      />
    </Routes>
  );
}
