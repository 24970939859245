import { CONTACT_INFO, GOSAFE_DRIVER, GOSAFE_USER, ROLE_DRIVER, ROLE_USER } from '@/shared/constant/text';
import { Card } from '@mui/material';

export default function PrivacyPolicy({ isUser = true }) {
  const textRole = isUser ? ROLE_USER : ROLE_DRIVER;
  const textGOSAFE = isUser ? GOSAFE_USER : GOSAFE_DRIVER;

  const arrayMapListDriver = [
    {
      title: '1. THÔNG TIN CÁ NHÂN ĐƯỢC CHÚNG TÔI THU THẬP',
      subTitle: `Chúng tôi thu thập thông tin cá nhân của Tài xế trong quá trình cung cấp Dịch vụ. Đối với Tài xế, hồ sơ cá nhân cung cấp cho quản trị viên bao gồm các thông tin như: họ và tên, chứng minh nhân dân hoặc căn cước công dân, giấy phép lái xe, ngày sinh, giới tính, địa chỉ cư trú, số điện thoại và địa chỉ email. Khi đăng ký trên ứng dụng, Tài xế chỉ cần cung cấp họ và tên, số điện thoại, địa chỉ email; các thông tin còn lại có thể được gửi kèm trong hồ sơ cho quản trị viên để xác thực tài khoản.  
      Dưới đây là các nguồn thông tin mà chúng tôi thu thập từ ${textRole}:`,
      dataSub: [
        `Thông tin tài xế cung cấp: Chúng tôi thu thập mọi thông tin mà ${textRole} cung cấp cho chúng tôi 
        liên quan đến việc sử dụng Dịch vụ.`,
        `Thông tin từ ứng dụng: Chúng tôi tự động thu thập thông tin khi ${textRole} sử dụng ứng dụng, 
        bao gồm vị trí, thời gian hoạt động và lịch sử giao dịch.`,
        `Thông tin từ bên thứ ba: Chúng tôi có thể thu thập thông tin về ${textRole} từ các nguồn hợp pháp, 
        chẳng hạn như các đối tác cung cấp dịch vụ hoặc cơ quan chính phủ.`,
      ],
    },
    {
      title: '2. CÁCH THỨC CHÚNG TÔI SỬ DỤNG THÔNG TIN CÁ NHÂN',
      subTitle: `Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để quản lý, cung cấp và nâng cao Dịch vụ của chúng tôi. 
      Mục đích sử dụng thông tin cá nhân của chúng tôi bao gồm:`,
      dataSub: [
        `Cung cấp Dịch vụ cho ${textRole}: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để cung cấp các Dịch vụ, 
        xử lý giao dịch và đảm bảo ${textRole} có trải nghiệm tốt nhất khi sử dụng dịch vụ.`,
        `Đánh giá và cải thiện Dịch vụ: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để theo dõi và phân tích 
        cách ${textRole} sử dụng dịch vụ, từ đó cải thiện hiệu suất và chất lượng Dịch vụ.`,
        `Đề xuất và cá nhân hóa trải nghiệm: Thông tin cá nhân của ${textRole} có thể được sử dụng để đề xuất các Dịch vụ 
        mà ${textRole} có thể quan tâm, cũng như cá nhân hóa trải nghiệm của ${textRole} với Dịch vụ của chúng tôi.`,
        `Tuân thủ quy định pháp luật: Trong một số trường hợp, chúng tôi có nghĩa vụ pháp lý để thu thập, sử dụng 
        hoặc lưu trữ thông tin cá nhân của ${textRole}.`,
        `Giao tiếp với ${textRole}: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để liên lạc và cập nhật 
        thông tin cho ${textRole} liên quan đến Dịch vụ qua các phương tiện khác nhau (ví dụ: email, tin nhắn) và để 
        trả lời các yêu cầu từ ${textRole}.`,
        `Tiếp thị và quảng bá: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} cho mục đích tiếp thị và 
        quảng bá Dịch vụ, theo quy định của pháp luật. Chúng tôi cũng có thể hiển thị quảng cáo phù hợp với sở thích của ${textRole}.`,
        `Ngăn chặn gian lận và bảo mật: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để ngăn chặn và phát hiện 
        các hành vi gian lận, bảo vệ sự an toàn cho ${textRole}, chúng tôi và cộng đồng.`,
        `Đồng ý của ${textRole} cho các mục đích cụ thể: Chúng tôi có thể yêu cầu sự đồng ý của ${textRole} cho việc 
        sử dụng thông tin cá nhân vào một số mục đích cụ thể khi liên hệ với ${textRole}.`,
      ],
    },
    {
      title: '3. THU THẬP VÀ SỬ DỤNG COOKIES',
      subTitle: `Chúng tôi sử dụng cookie, pixel và các công nghệ tương tự (gọi chung là “cookie”) để nhận diện thiết bị của ${textRole}, tìm hiểu thêm về sở thích và hành vi của ${textRole}, cung cấp các tính năng và dịch vụ thiết yếu, cũng như cho các mục đích bổ sung khác, bao gồm:`,
      dataSub: [
        `Nhận diện ${textRole} khi sử dụng dịch vụ của chúng tôi. Điều này cho phép chúng tôi cung cấp cho ${textRole} các đề xuất phù hợp, hiển thị nội dung được cá nhân hóa và mang đến các tính năng dịch vụ tùy chỉnh.`,
        `Lưu lại các tùy chọn mà ${textRole} đã chọn. Điều này cho phép chúng tôi tôn trọng sở thích của ${textRole}, chẳng hạn như ngôn ngữ và cài đặt mà ${textRole} đã thiết lập.`,
        `Tiến hành nghiên cứu và phân tích để cải thiện Dịch vụ và tăng cường trải nghiệm của ${textRole}.`,
        `Ngăn chặn hành vi gian lận và bảo vệ an ninh của Dịch vụ.`,
        `Cải thiện mức độ bảo mật cho thông tin và dữ liệu của ${textRole}.`,
        `Cung cấp nội dung, bao gồm quảng cáo, có liên quan đến sở thích và nhu cầu của ${textRole} trên ứng dụng của chúng tôi và trang web của bên thứ ba.`,
        `Đo lường và phân tích chất lượng của các Dịch vụ mà chúng tôi cung cấp cho ${textRole}.`,
      ],
      sub3: `Cookies giúp ${textRole} tận dụng một số tính năng thiết yếu của chúng tôi. Nếu ${textRole} chặn hoặc từ chối cookies, ${textRole} có thể không sử dụng một số dịch vụ yêu cầu đăng nhập, hoặc có thể phải điều chỉnh lại một số tùy chọn và cài đặt mỗi lần truy cập lại ứng dụng của chúng tôi.`,
      sub4: `Các bên thứ ba được chấp thuận cũng có thể đặt cookie khi ${textRole} tương tác với các Dịch vụ của chúng tôi. Những bên thứ ba này thường bao gồm công cụ tìm kiếm, nhà cung cấp dịch vụ đo lường và phân tích, mạng xã hội và các công ty quảng cáo. Họ sử dụng cookies để cung cấp nội dung, bao gồm quảng cáo liên quan đến sở thích của ${textRole}, nhằm đo lường hiệu quả của quảng cáo và thực hiện một số dịch vụ thay mặt chúng tôi.`,
    },
    {
      title: '4. CÁCH THỨC CHÚNG TÔI CHIA SẺ THÔNG TIN CÁ NHÂN',
      subTitle: `Thông tin về ${textRole} rất quan trọng trong hoạt động của chúng tôi, và chúng tôi cam kết không cung cấp thông tin cá nhân của ${textRole} cho bất kỳ bên thứ ba nào, trừ những trường hợp được nêu dưới đây:`,
      dataSub: [
        `Giao dịch với bên thứ ba: Chúng tôi cung cấp cho ${textRole} các dịch vụ, phần mềm và nội dung do bên thứ ba cung cấp để sử dụng trên hoặc thông qua Dịch vụ của chúng tôi. ${textRole} có thể nhận biết khi bên thứ ba tham gia vào các giao dịch của mình, và chúng tôi sẽ chia sẻ thông tin cần thiết liên quan đến các giao dịch đó với bên thứ ba.`,
        `Các bên thứ ba cung cấp dịch vụ: Chúng tôi hợp tác với các công ty và cá nhân khác để thực hiện một số công việc như chương trình ưu đãi, xử lý thanh toán, và quản lý dữ liệu. Ví dụ, chúng tôi có thể sử dụng bên thứ ba để gửi thông tin, phân tích rủi ro tín dụng, cung cấp hỗ trợ tiếp thị và quản lý mối quan hệ với ${textRole}. Những bên thứ ba này chỉ được phép truy cập thông tin cá nhân cần thiết để thực hiện nhiệm vụ của họ và phải tuân thủ Chính sách quyền riêng tư và pháp luật liên quan.`,
        `Tái cấu trúc và chuyển nhượng doanh nghiệp: Trong quá trình phát triển, chúng tôi có thể bán hoặc mua doanh nghiệp hoặc tái cấu trúc công ty. Trong những giao dịch như vậy, thông tin cá nhân và cơ sở dữ liệu của ${textRole} có thể được chuyển nhượng, nhưng bên nhận chuyển nhượng vẫn phải tuân theo Chính sách quyền riêng tư này, trừ khi được ${textRole} chấp thuận.`,
        `Bảo vệ chúng tôi và người khác: Chúng tôi có thể tiết lộ thông tin tài khoản và cá nhân của ${textRole} khi thấy cần thiết để tuân thủ pháp luật, thực thi các thỏa thuận hoặc bảo vệ quyền lợi và an ninh của chúng tôi và ${textRole}. Điều này có thể bao gồm việc trao đổi thông tin với các tổ chức khác nhằm ngăn chặn gian lận và giảm thiểu rủi ro.`,
        `Tùy thuộc vào sự lựa chọn của ${textRole}: Ngoài các điều khoản trên, ${textRole} sẽ được thông báo nếu thông tin cá nhân của mình có thể được chia sẻ với bên thứ ba và có quyền từ chối việc chia sẻ này.`,
      ],
    },
    {
      title: '5. LƯU TRỮ THÔNG TIN CÁ NHÂN',
      subTitle: `Thông tin cá nhân của ${textRole} được chúng tôi lưu trữ theo đúng quy định của pháp luật, và chúng tôi áp dụng các biện pháp kỹ thuật phù hợp để đảm bảo thông tin luôn được bảo mật và an toàn.`,
    },
    {
      title: '6. CÁCH THỨC CHÚNG TÔI BẢO VỆ THÔNG TIN CÁ NHÂN',
      subTitle: `Tại ${textGOSAFE}, bảo mật là ưu tiên hàng đầu của chúng tôi. Hệ thống của chúng tôi được thiết kế để bảo vệ thông tin của ${textRole} .`,
      dataSub: [
        `Chúng tôi thực hiện các biện pháp an ninh cần thiết để ngăn chặn việc truy cập và sử dụng trái phép thông tin cá nhân. Chúng tôi thường xuyên làm việc với các chuyên gia bảo mật để cập nhật những giải pháp mới nhất về an ninh mạng, nhằm bảo vệ an toàn cho thông tin cá nhân. Khi thu thập dữ liệu, chúng tôi lưu trữ và bảo mật thông tin cá nhân trên hệ thống máy chủ với các biện pháp như tường lửa và mã hóa dữ liệu.`,
        `Các thông tin thẻ thanh toán của ${textRole} được bảo vệ theo tiêu chuẩn quốc tế. Chúng tôi không lưu trữ các dữ liệu nhạy cảm của thẻ thanh toán (số thẻ, họ tên, số CVV) trên hệ thống của mình. Giao dịch thanh toán của ${textRole} được thực hiện qua hệ thống của ngân hàng có liên quan.`,
      ],
    },
    {
      title: '7. QUẢNG CÁO TRÊN INTERNET VÀ BÊN THỨ BA',
      subTitle: `Các dịch vụ của chúng tôi có thể bao gồm quảng cáo từ bên thứ ba và liên kết tới các trang web, ứng dụng khác. Các đối tác quảng cáo bên thứ ba có thể thu thập thông tin về ${textRole} khi ${textRole} tương tác với nội dung hoặc dịch vụ của họ.`,
    },
    {
      title: '8. TRUY CẬP VÀ LỰA CHỌN',
      subTitle: `${textRole} có quyền xem, cập nhật và xóa một số thông tin nhất định về tài khoản và các tương tác của ${textRole} với Dịch vụ của chúng tôi. Nếu ${textRole} không thể tự truy cập hoặc cập nhật thông tin của mình, vui lòng liên hệ với chúng tôi để được hỗ trợ. ${textRole} cũng có nhiều lựa chọn về việc thu thập và sử dụng thông tin cá nhân. Nhiều Dịch vụ của chúng tôi cung cấp các tính năng cho phép ${textRole} tùy chỉnh cách thông tin của mình được sử dụng. ${textRole} có thể chọn không cung cấp một số thông tin nhất định, nhưng điều này có thể ảnh hưởng đến khả năng sử dụng một số Dịch vụ của chúng tôi.`,
      dataSub: [
        `Thông tin tài khoản: Nếu ${textRole} muốn bổ sung, cập nhật hoặc xóa thông tin liên quan đến tài khoản của mình, vui lòng truy cập vào tài khoản của ${textRole} trong ứng dụng để thực hiện. Khi ${textRole} cập nhật hoặc xóa thông tin, hãy đảm bảo rằng thông tin được cung cấp là chính xác và đầy đủ.`,
        `Thiết bị: Tính năng trợ giúp trên hầu hết các thiết bị cho phép ${textRole} quản lý thông tin và quyền riêng tư của mình dễ dàng hơn.`,
      ],
    },
    {
      title: '9. THỜI HẠN LƯU TRỮ THÔNG TIN CÁ NHÂN',
      subTitle: `Chúng tôi lưu trữ thông tin cá nhân của ${textRole} để đảm bảo rằng ${textRole} có thể sử dụng liên tục các Dịch vụ của chúng tôi. Chúng tôi sẽ lưu trữ thông tin trong thời gian cần thiết để thực hiện các mục tiêu theo quy định tại Chính sách quyền riêng tư này, theo quy định pháp luật (bao gồm cả mục đích thuế và kế toán), hoặc để thực hiện các công việc khác đã được thông báo trước cho ${textRole}. Thời gian lưu giữ thông tin cá nhân cụ thể có thể khác nhau tùy thuộc vào mục đích sử dụng, và chúng tôi sẽ xóa thông tin cá nhân của ${textRole} theo quy định pháp luật hiện hành.`,
    },
    {
      title: '10. THÔNG TIN LIÊN LẠC, THÔNG BÁO VÀ SỬA ĐỔI',
      subTitle: `Nếu ${textRole} có bất kỳ câu hỏi nào về quyền riêng tư tại ${textGOSAFE} hoặc muốn liên hệ với đơn vị kiểm soát thông tin của chúng tôi, xin vui lòng liên hệ với chúng tôi và chúng tôi sẽ cố gắng trả lời câu hỏi của ${textRole}. ${textRole} cũng có thể liên hệ với chúng tôi theo thông tin dưới đây:`,
      dataSub: [
        `Địa chỉ: 71 Ngô Thì Hương, Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng`,
        `Email: ${CONTACT_INFO.email}`,
        `Điện thoại: 0374180180`,
        `Hoạt động kinh doanh của chúng tôi liên tục thay đổi, và Chính sách quyền riêng tư này có thể được sửa đổi. ${textRole} nên truy cập và kiểm tra trang web của chúng tôi thường xuyên để cập nhật những thay đổi gần nhất. Trừ khi có quy định khác, Chính sách quyền riêng tư hiện tại áp dụng cho tất cả thông tin cá nhân mà chúng tôi có về ${textRole} và tài khoản của ${textRole}. Việc sửa đổi Chính sách này sẽ không làm giảm mức độ bảo vệ thông tin cá nhân được thu thập trong quá khứ mà không thông báo cho ${textRole} và cho ${textRole} quyền lựa chọn.`,
      ],
    },
    {
      title: `11. VÍ DỤ VỀ CÁC THÔNG TIN ĐƯỢC CHÚNG TÔI THU THẬP`,
      subTitle: `Thông tin ${textRole} cung cấp cho chúng tôi. ${textRole} chủ yếu cung cấp thông tin cho chúng tôi khi:`,
      dataSub: [
        `Tìm kiếm, đăng ký hoặc sử dụng Dịch vụ của chúng tôi;`,
        `Tạo hoặc quản lý tài khoản của ${textRole};`,
        `Cấu hình cài đặt của ${textRole} để cung cấp quyền truy cập dữ liệu cho hoặc tương tác với Dịch vụ của chúng tôi;`,
        `Mua hoặc sử dụng nội dung hoặc dịch vụ từ các nhà cung cấp bên thứ ba thông qua Dịch vụ của chúng tôi;`,
        `Cung cấp nội dung hoặc dịch vụ của ${textRole} trên hoặc thông qua Dịch vụ của chúng tôi;`,
        `Liên lạc với chúng tôi qua tất cả các phương tiện chính thống như tổng đài chăm sóc ${textRole}, gọi điện, email;`,
        `Hoàn thành bảng câu hỏi hoặc các phiếu cung cấp thông tin khác;`,
        `Đăng tải nội dung trên Dịch vụ của chúng tôi.`,
      ],
      sub3: `Tùy thuộc vào việc ${textRole} sử dụng Dịch vụ của chúng tôi, ${textRole} có thể cung cấp cho chúng tôi các thông tin ví dụ như:`,
      dataSub2: [
        `Tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên lạc tương tự khác;`,
        `Thông tin thanh toán, bao gồm thông tin thẻ tín dụng và tài khoản ngân hàng;`,
        `Thông tin về vị trí của ${textRole};`,
        `Thông tin về tổ chức của ${textRole} và đầu mối liên hệ của ${textRole}, chẳng hạn như đồng nghiệp hoặc những người trong tổ chức của ${textRole};`,
        `Tên ${textRole} và thông tin xác thực và bảo mật khác;`,
        `Nội dung phản hồi, lời chứng thực, câu hỏi, trao đổi với chúng tôi;`,
      ],
      sub4: `Chúng tôi chủ yếu thu thập thông tin tự động khi ${textRole}:`,
      dataSub3: [
        `Truy cập, tương tác hoặc sử dụng Dịch vụ của chúng tôi (kể cả khi ${textRole} sử dụng thiết bị khác để tương tác với Dịch vụ của chúng tôi);`,
        `Tải nội dung từ chúng tôi;`,
        `Mở email hoặc nhấp vào liên kết trong email từ chúng tôi; và`,
        `Tương tác hoặc liên lạc với chúng tôi.`,
      ],
      sub5: `Ví dụ về các thông tin được chúng tôi thu thập tự động:`,
      dataSub4: [
        `Thông tin mạng và kết nối, thông tin về nhà cung cấp dịch vụ Internet của ${textRole};`,
        `Thông tin về thiết bị, hệ điều hành hoặc cài đặt múi giờ;`,
        `Vị trí của thiết bị;`,
        `Thông tin xác thực và bảo mật;`,
        `Các số liệu Dịch vụ của chúng tôi, chẳng hạn như việc sử dụng Dịch vụ, lỗi kỹ thuật, báo cáo, tùy chọn cài đặt của ${textRole}, thông tin sao lưu, API calls, và nhật ký khác;`,
        `Địa chỉ email và số điện thoại được sử dụng để liên hệ với chúng tôi.`,
      ],
      sub6: `Thông tin từ các nguồn khác. Ví dụ về các thông tin chúng tôi có được từ các nguồn khác:`,
      dataSub5: [
        `Thông tin tiếp thị, tạo doanh số và tuyển dụng, bao gồm tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên hệ tương tự khác;`,
        `Thông tin về đăng ký, mua, hỗ trợ hoặc thông tin khác về tương tác của ${textRole} với các Dịch vụ của chúng tôi hoặc với các Dịch vụ của bên thứ ba liên quan đến Dịch vụ của chúng tôi;`,
        `Kết quả tìm kiếm và liên kết, bao gồm các danh sách Dịch vụ được trả tiền (như Liên kết được Tài trợ); và`,
        `Thông tin lịch sử tín dụng.`,
      ],
    },
    {
      title: `12. GIẢI QUYẾT KHIẾU NẠI`,
      subTitle: `Quy trình giải quyết khiếu nại của ${textGOSAFE}`,
      dataSub: [
        `Bước 1: Bộ phận Chăm Sóc ${textRole} của ${textGOSAFE} sẽ tiếp nhận các khiếu nại của ${textRole}, chủ động giải quyết nhanh chóng và trả lời ngay kết quả giải quyết các khiếu nại nếu có cơ sở trong các chính sách mà chúng tôi đã công bố.`,
        `Bước 2: Trong trường hợp phức tạp hoặc không được quy định trong các chính sách mà chúng tôi đã công bố, Bộ phận Chăm Sóc ${textRole} sẽ cam kết thời hạn phản hồi cho ${textRole} không quá 7 (bảy) ngày.`,
        `Bước 3: Chuyển kết quả giải quyết khiếu nại cho các bộ phận có liên quan để thực hiện và gọi điện xác nhận với ${textRole} về kết quả khiếu nại đã được giải quyết.`,
      ],
    },
  ];

  const arrayMapList = [
    {
      title: `1. THÔNG TIN CÁ NHÂN ĐƯỢC CHÚNG TÔI THU THẬP`,
      subTitle: `Chúng tôi thu thập thông tin cá nhân của ${textRole} trong quá trình cung cấp Dịch vụ của Chúng tôi cho ${textRole}. Thông tin cá nhân là thông tin đủ để xác định chính xác danh tính một cá nhân, bao gồm ít nhất nội dung trong những thông tin sau đây: Họ tên, ngày sinh, giới tính, nơi sống, số điện thoại, địa chỉ email. Dưới đây là các nguồn thông tin được Chúng tôi thu thập:`,
      dataSub: [
        `Thông tin ${textRole} cung cấp cho Chúng tôi: Chúng tôi thu thập bất kỳ thông tin nào ${textRole} cung cấp liên quan đến Dịch vụ của Chúng tôi`,
        `Thông tin tự động: Chúng tôi tự động thu thập một số loại thông tin nhất định khi ${textRole} tương tác với Dịch vụ của Chúng tôi.`,
        `Thông tin từ các nguồn khác: Chúng tôi có thể thu thập thông tin về ${textRole} từ các nguồn hợp pháp và công khai có sẵn khác.`,
      ],
    },
    {
      title: `2. CÁCH THỨC CHÚNG TÔI SỬ DỤNG THÔNG TIN CÁ NHÂN`,
      subTitle: `Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để vận hành, cung cấp và cải thiện Dịch vụ của Chúng tôi. Mục đích sử dụng thông tin cá nhân của Chúng tôi bao gồm:`,
      dataSub: [
        `Cung cấp Dịch vụ của Chúng tôi: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để cung cấp Dịch vụ của Chúng tôi và xử lý các giao dịch liên quan đến Dịch vụ của Chúng tôi.`,
        `Đo lường, hỗ trợ và cải thiện Dịch vụ của Chúng tôi: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để đo lường việc sử dụng, phân tích hiệu suất, sửa lỗi, cung cấp hỗ trợ, cải thiện chất lượng và phát triển Dịch vụ của Chúng tôi.`,
        `Đưa ra khuyến nghị và cá nhân hóa trải nghiệm: Chúng tôi có thể sử dụng thông tin cá nhân của ${textRole} để đề xuất Dịch vụ của Chúng Tôi mà ${textRole} có thể quan tâm, nhận diện sở thích của ${textRole} và cá nhân hóa trải nghiệm của ${textRole} với Dịch vụ của Chúng Tôi.`,
        `Tuân thủ nghĩa vụ pháp lý: Trong một số trường hợp nhất định, Chúng Tôi có nghĩa vụ pháp lý để thu thập, sử dụng hoặc lưu trữ thông tin cá nhân của ${textRole}.`,
        `Liên lạc với ${textRole}: Chúng Tôi có thể sử dụng thông tin cá nhân của ${textRole} để liên lạc với ${textRole} liên quan đến Dịch vụ của Chúng Tôi thông qua các kênh khác nhau (ví dụ: email, chat) và để trả lời yêu cầu của ${textRole}.`,
        `Tiếp thị: Chúng Tôi có thể sử dụng thông tin cá nhân của ${textRole} để tiếp thị và quảng bá Dịch vụ của Chúng Tôi theo quy định của pháp luật. Chúng Tôi có thể hiển thị quảng cáo Dịch vụ của Chúng Tôi dựa trên sở thích của ${textRole}.`,
        `Phòng chống gian lận và lạm dụng, rủi ro tín dụng: Chúng Tôi có thể sử dụng thông tin cá nhân của ${textRole} để ngăn chặn, phát hiện gian lận và lạm dụng nhằm bảo vệ an ninh của ${textRole}, Chúng Tôi và những người khác. Chúng Tôi cũng có thể sử dụng các phương pháp chấm điểm để đánh giá và quản trị rủi ro tín dụng.`,
        `Một số mục đích cụ thể cần sự đồng ý của ${textRole}: Chúng Tôi có thể xin chấp thuận của ${textRole} về việc sử dụng thông tin cá nhân cho một mục đích cụ thể nào đó khi Chúng Tôi liên hệ với ${textRole}.`,
      ],
    },
    {
      title: `3. THU THẬP VÀ SỬ DỤNG COOKIES`,
      subTitle: `Chúng tôi sử dụng cookie, pixel và các công nghệ tương tự khác (gọi chung là “cookie”) để nhận diện thiết bị của ${textRole}, tìm hiểu thêm về sở thích của ${textRole}, cung cấp cho ${textRole} các tính năng và dịch vụ thiết yếu và cho các mục đích bổ sung khác, bao gồm:`,
      dataSub: [
        `Nhận diện ${textRole} khi ${textRole} đăng nhập sử dụng dịch vụ của Chúng Tôi. Điều này cho phép Chúng Tôi cung cấp cho ${textRole} các đề xuất, hiển thị nội dung được cá nhân hóa và cung cấp các tính năng và dịch vụ tùy chỉnh khác.`,
        `Lưu tâm đến các tùy chọn mà ${textRole} đã chấp thuận. Điều này cho phép Chúng Tôi tôn trọng những điều ${textRole} thích và không thích, chẳng hạn như ngôn ngữ và cấu hình mà ${textRole} lựa chọn.`,
        `Tiến hành nghiên cứu và phân tích để cải thiện Dịch vụ của Chúng Tôi.`,
        `Ngăn chặn hành vi gian lận.`,
        `Cải thiện an ninh.`,
        `Cung cấp nội dung, bao gồm quảng cáo, có liên quan đến sở thích của ${textRole} trên ứng dụng của Chúng Tôi và trang web của bên thứ ba.`,
        `Đo lường và phân tích chất lượng của các Dịch vụ của Chúng Tôi.`,
      ],
      sub3: `Cookie cho phép ${textRole} tận dụng một số tính năng cần thiết của Chúng tôi. Ví dụ, nếu ${textRole} chặn hoặc từ chối cookie của Chúng tôi, ${textRole} sẽ không thể sử dụng một số dịch vụ nhất định yêu cầu ${textRole} đăng nhập hoặc ${textRole} có thể phải tự tay điều chỉnh một số tùy chọn hoặc cài đặt ngôn ngữ mỗi khi ${textRole} truy cập lại các ứng dụng của Chúng tôi.`,
      sub4: `Các bên thứ ba được chấp thuận cũng có thể đặt cookie khi ${textRole} tương tác với các Dịch vụ của Chúng tôi. Các bên thứ ba này thường bao gồm các công cụ tìm kiếm, nhà cung cấp dịch vụ đo lường và phân tích, mạng xã hội và các công ty quảng cáo. Các bên thứ ba sử dụng cookie trong quá trình cung cấp nội dung, bao gồm quảng cáo liên quan đến sở thích của ${textRole}, để đo lường hiệu quả của quảng cáo và thực hiện một số dịch vụ thay mặt cho Chúng tôi. `,
    },
    {
      title: `4. CÁCH THỨC CHÚNG TÔI CHIA SẺ THÔNG TIN CÁ NHÂN`,
      subTitle: `Thông tin về ${textRole} là một phần quan trọng trong hoạt động của Chúng tôi, và Chúng tôi không cung cấp thông tin cá nhân của ${textRole} cho bất kỳ bên thứ ba nào trừ các trường hợp được nêu tại mục (i).`,
      dataSub: [
        `Giao dịch liên quan đến bên thứ ba: Chúng tôi cung cấp cho ${textRole} các dịch vụ, phần mềm và nội dung do bên thứ ba cung cấp để sử dụng trên hoặc thông qua Dịch vụ Của Chúng tôi. ${textRole} có thể biết khi nào bên thứ ba tham gia vào các giao dịch của ${textRole} và Chúng tôi sẽ chia sẻ thông tin liên quan đến các giao dịch đối với bên thứ ba đó.`,
        `Bên thứ ba cung cấp dịch vụ: Chúng tôi sử dụng và/hoặc hợp tác với các công ty và cá nhân khác để thực hiện một số công việc và chương trình như chương trình ưu đãi, bán hàng chéo,… dành cho ${textRole}. Ví dụ bao gồm: gửi thông tin liên lạc, xử lý thanh toán, đánh giá rủi ro tín dụng và tuân thủ, thực hiện chương trình hỗ trợ lãi suất, phân tích dữ liệu, cung cấp hỗ trợ tiếp thị và bán hàng (bao gồm quản lý quảng cáo và sự kiện), quản lý quan hệ ${textRole} và đào tạo. Các bên thứ ba cung cấp dịch vụ này có quyền truy cập vào thông tin cá nhân cần thiết để thực hiện các chức năng của họ, nhưng không được sử dụng cho các mục đích khác. Ngoài ra, họ phải tuân thủ Chính sách quyền riêng tư này và pháp luật về bảo vệ quyền riêng tư liên quan.`,
        `Tái cấu trúc, Chuyển nhượng doanh nghiệp: Trong quá trình phát triển kinh doanh, Chúng tôi có thể bán hoặc mua các doanh nghiệp hoặc tái cấu trúc doanh nghiệp hoặc dịch vụ khác phù hợp với quy định của pháp luật. Trong các giao dịch như vậy, thông tin cá nhân, cơ sở dữ liệu và quyền sử dụng thông tin nói chung là một trong những tài sản kinh doanh được chuyển nhượng nhưng bên nhận chuyển nhượng vẫn phải tuân theo các quy định của Chính sách quyền riêng tư này (hoặc khi được ${textRole} chấp thuận). Ngoài ra, trong trường hợp ${textGOSAFE} hoặc phần lớn tài sản của ${textGOSAFE} được một công ty khác mua, thông tin của ${textRole} sẽ trở thành một trong những tài sản được chuyển nhượng.`,
        `Bảo vệ Chúng tôi và những người khác: Chúng tôi được phép tiết lộ tài khoản và thông tin cá nhân khác khi Chúng tôi tin rằng việc đó là phù hợp để tuân thủ pháp luật, để thực thi hoặc áp dụng các điều khoản và thỏa thuận khác của Chúng tôi hoặc để bảo vệ quyền, tài sản hoặc an ninh của Chúng tôi, ${textRole} của Chúng Tôi, hoặc bất kỳ người nào khác. Các công việc nêu trên có thể bao gồm việc trao đổi thông tin với các công ty và tổ chức khác để ngăn chặn và phát hiện gian lận và giảm rủi ro tài sản và tín dụng.`,
        `Tùy theo sự lựa chọn của ${textRole}: Ngoài các quy định trên, ${textRole} sẽ nhận được thông báo khi thông tin cá nhân về ${textRole} có thể được chia sẻ với bên thứ ba và ${textRole} sẽ có quyền không chấp thuận việc chia sẻ thông tin.`,
      ],
    },
    {
      title: `5. LƯU TRỮ THÔNG TIN CÁ NHÂN`,
      subTitle: `Thông tin cá nhân của ${textRole} được chúng tôi lưu trữ theo đúng quy định của pháp luật liên quan và áp dụng các biện pháp kỹ thuật phù hợp để đảm bảo thông tin được lưu trữ an toàn và bảo mật.`,
    },
    {
      title: `6. CÁCH THỨC CHÚNG TÔI BẢO VỆ THÔNG TIN CÁ NHÂN`,
      subTitle: `Tại ${textGOSAFE}, bảo mật là ưu tiên cao nhất của Chúng tôi. Hệ thống của Chúng tôi được thiết kế có tính đến khả năng bảo đảm an toàn và riêng tư cho thông tin của ${textRole} và tài xế`,
      dataSub: [
        `Chúng tôi có các biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn việc truy cập, sử dụng trái phép thông tin cá nhân. Chúng tôi cũng thường xuyên phối hợp với các chuyên gia bảo mật nhằm cập nhật những thông tin mới nhất về an ninh mạng để đảm bảo sự an toàn cho thông tin cá nhân. Khi thu thập dữ liệu, Chúng tôi thực hiện lưu giữ và bảo mật thông tin cá nhân tại hệ thống máy chủ và các thông tin cá nhân này được bảo đảm an toàn bằng các hệ thống tường lửa, các biện pháp kiểm soát truy cập, mã hóa dữ liệu.`,
        `Các thông tin thẻ thanh toán của ${textRole} do các tổ chức tài chính phát hành được Chúng tôi bảo vệ theo tiêu chuẩn quốc tế với nguyên tắc không ghi nhận các dữ liệu quan trọng của thẻ thanh toán (số thẻ, họ tên, số CVV) trên hệ thống của Chúng tôi. Giao dịch thanh toán của ${textRole} được thực hiện trên hệ thống của ngân hàng liên quan.`,
      ],
    },
    {
      title: `7. QUẢNG CÁO TRÊN INTERNET VÀ BÊN THỨ BA`,
      subTitle: `Các Dịch vụ của Chúng tôi có thể bao gồm quảng cáo của bên thứ ba và đường link tới các trang web và ứng dụng khác. Các đối tác quảng cáo bên thứ ba có thể thu thập thông tin về ${textRole} khi ${textRole} tương tác với nội dung, quảng cáo hoặc dịch vụ của họ.`,
    },
    {
      title: `8. TRUY CẬP VÀ LỰA CHỌN`,
      subTitle: `${textRole} có thể xem, cập nhật và xóa một số thông tin nhất định về tài khoản và các tương tác của ${textRole} với Dịch vụ của Chúng tôi. Nếu không thể tự truy cập hoặc cập nhật thông tin của mình, ${textRole} luôn có thể liên hệ với Chúng tôi để được hỗ trợ. ${textRole} có nhiều lựa chọn về việc thu thập và sử dụng thông tin cá nhân của ${textRole}. Nhiều Dịch vụ của Chúng tôi bao gồm chức năng cho phép ${textRole} tùy chọn về cách thông tin của ${textRole} đang được sử dụng. ${textRole} có thể chọn không cung cấp một số thông tin nhất định, nhưng sau đó ${textRole} có thể không tận dụng được một số Dịch vụ của Chúng tôi.`,
      dataSub: [
        `Thông tin tài khoản: Nếu ${textRole} muốn bổ sung, cập nhật hoặc xóa thông tin liên quan đến tài khoản của mình, vui lòng truy cập vào tài khoản của ${textRole} tại ứng dụng để thực hiện. Khi ${textRole} cập nhật hoặc xóa bất kỳ thông`,
        `Thiết bị: Tính năng Trợ giúp trên hầu hết các thiết bị.`,
      ],
    },
    {
      title: `9. THỜI HẠN LƯU TRỮ THÔNG TIN CÁ NHÂN`,
      subTitle: `Chúng tôi lưu trữ thông tin cá nhân của ${textRole} để đảm bảo cho ${textRole} khả năng sử dụng liên tục các Dịch vụ của Chúng tôi, và lưu trữ trong thời hạn cần thiết để thực hiện được các mục tiêu quy định tại Chính sách quyền riêng tư này, hoặc theo quy định của pháp luật (bao gồm cả cho mục đích thuế và kế toán), hoặc để thực hiện các công việc khác như được thông báo trước cho ${textRole}. Thời gian Chúng tôi lưu giữ thông tin cá nhân cụ thể khác nhau tùy thuộc vào mục đích sử dụng và Chúng tôi sẽ xóa thông tin cá nhân của ${textRole} theo quy định của pháp luật hiện hành.`,
    },
    {
      title: `10. THÔNG TIN LIÊN LẠC, THÔNG BÁO VÀ SỬA ĐỔI`,
      subTitle: `Nếu ${textRole} có bất kỳ câu hỏi nào về quyền riêng tư tại ${textGOSAFE} hoặc muốn liên hệ với đơn vị kiểm soát thông tin của Chúng tôi, vui lòng liên hệ với Chúng tôi và Chúng tôi sẽ cố gắng trả lời câu hỏi của ${textRole}. ${textRole} cũng có thể liên hệ với Chúng tôi theo địa chỉ dưới đây:
Địa chỉ: 71 Ngô Thì Hương, Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng
Email: ${CONTACT_INFO.email}
Điện thoại: 0374180180
Hoạt động kinh doanh của Chúng tôi liên tục thay đổi và Chính sách quyền riêng tư này cũng có thể được sửa đổi, ${textRole} nên truy cập và kiểm tra trang web của Chúng tôi thường xuyên để cập nhật những thay đổi gần nhất. Trừ trường hợp có quy định khác, Chính sách quyền riêng tư hiện tại của Chúng tôi áp dụng cho tất cả thông tin cá nhân Chúng tôi có về ${textRole} và tài khoản của ${textRole}. Việc sửa đổi Chính sách này trong mọi trường hợp sẽ không làm giảm mức độ bảo vệ thông tin cá nhân được thu thập trong quá khứ mà không thông báo cho ${textRole} liên quan và cho ${textRole} được quyền lựa chọn.
`,
    },
    {
      title: `11. VÍ DỤ VỀ CÁC THÔNG TIN ĐƯỢC CHÚNG TÔI THU THẬP`,
      subTitle: `Thông tin ${textRole} cung cấp cho Chúng tôi \n. ${textRole} chủ yếu cung cấp thông tin cho Chúng tôi khi ${textRole}: `,
      dataSub: [
        `Tìm kiếm, đăng ký hoặc sử dụng Dịch vụ của Chúng tôi;`,
        `Tạo hoặc quản lý tài khoản của ${textRole};`,
        `Cấu hình cài đặt của ${textRole} cho, cung cấp quyền truy cập dữ liệu cho, hoặc tương tác với Dịch vụ của Chúng tôi;`,
        `Mua hoặc sử dụng nội dung hoặc dịch vụ từ các nhà cung cấp bên thứ ba thông qua Dịch vụ của chúng tôi;`,
        `Cung cấp nội dung hoặc dịch vụ của ${textRole} trên hoặc thông qua Dịch vụ của Chúng tôi;`,
        `Liên lạc với Chúng tôi qua tất cả các phương tiện chính thống như tổng đài chăm sóc ${textRole}, gọi điện, email;`,
        `Hoàn thành bảng câu hỏi hoặc các phiếu cung cấp thông tin khác;`,
        `Đăng tải nội dung trên Dịch vụ của Chúng tôi.`,
      ],
      sub3: `Tùy thuộc vào việc ${textRole} sử dụng Dịch vụ của Chúng tôi, ${textRole} có thể cung cấp cho Chúng tôi các thông tin ví dụ như:`,
      dataSub2: [
        `Tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên lạc tương tự khác;`,
        `Thông tin thanh toán, bao gồm thông tin thẻ tín dụng và tài khoản ngân hàng;`,
        `Thông tin về vị trí của ${textRole};`,
        `Thông tin về tổ chức của ${textRole} và đầu mối liên hệ của ${textRole}, chẳng hạn như đồng nghiệp hoặc những người trong tổ chức của ${textRole};`,
        `Tên ${textRole} và thông tin xác thực và bảo mật khác;`,
        `Nội dung phản hồi, lời chứng thực, câu hỏi, trao đổi với Chúng tôi;`,
      ],
      sub4: `Chúng tôi chủ yếu thu thập thông tin tự động khi ${textRole}:      `,
      dataSub3: [
        `Truy cập, tương tác hoặc sử dụng Dịch vụ của Chúng tôi (kể cả khi ${textRole} sử dụng thiết bị khác để tương tác với Dịch vụ của Chúng tôi);`,
        `Tải nội dung từ Chúng tôi;`,
        `Mở email hoặc nhấp vào liên kết trong email từ Chúng tôi; và`,
        `Tương tác hoặc liên lạc với Chúng tôi.`,
      ],
      sub5: `Ví dụ về các thông tin được Chúng tôi thu thập tự động:`,
      dataSub4: [
        `Thông tin mạng và kết nối, thông tin về nhà cung cấp dịch vụ Internet của ${textRole};`,
        `Thông tin về thiết bị, hệ điều hành hoặc cài đặt múi giờ;`,
        `Vị trí của thiết bị;`,
        `Thông tin xác thực và bảo mật;`,
        `Các số liệu Dịch vụ của Chúng tôi, chẳng hạn như việc sử dụng Dịch vụ, lỗi kỹ thuật, báo cáo, tùy chọn cài đặt của ${textRole}, thông tin sao lưu backup, API calls, và nhật ký khác;`,
        `Địa chỉ email và số điện thoại được sử dụng để liên hệ với Chúng tôi.`,
      ],
      sub6: `Thông tin từ các nguồn khác
      Ví dụ về các thông tin Chúng tôi có được từ các nguồn khác:
      `,
      dataSub5: [
        `Thông tin tiếp thị, tạo doanh số và tuyển dụng, bao gồm tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên hệ tương tự khác;`,
        `Thông tin về đăng ký, mua, hỗ trợ hoặc thông tin khác về tương tác của ${textRole} với các Dịch vụ của Chúng tôi hoặc với các Dịch vụ của bên thứ ba liên quan đến Dịch vụ của Chúng tôi;`,
        `Kết quả tìm kiếm và liên kết, bao gồm các danh sách Dịch vụ được trả tiền (như Liên kết được Tài trợ); và`,
        `Thông tin lịch sử tín dụng.`,
      ],
    },
    {
      title: `12. GIẢI QUYẾT KHIẾU NẠI`,
      subTitle: `Quy trình giải quyết khiếu nại của ${textGOSAFE}`,
      dataSub: [
        `Bước 1: Bộ phận Chăm Sóc ${textRole} của ${textGOSAFE} sẽ tiếp nhận các khiếu nại của ${textRole}, chủ động giải quyết nhanh chóng và trả lời ngay kết quả giải quyết các khiếu nại trên nếu có cơ sở các chính sách mà Chúng tôi đã công bố.`,
        `Bước 2: Trong trường hợp phức tạp hoặc không được quy định tại các chính sách mà Chúng tôi đã công bố thì Bộ phận Chăm Sóc ${textRole} sẽ cam kết thời hạn phản hồi cho ${textRole} không quá 7 (bảy) ngày.`,
        `Bước 3: Chuyển kết quả giải quyết khiếu nại cho các bộ phận có liên quan để thực hiện và gọi điện xác nhận với ${textRole} về kết quả khiếu nại đã được giải quyết.`,
      ],
    },
  ];

  const InfoItem = ({ label, children }) => {
    return (
      <span>
        <strong>{label}</strong>: {children}
      </span>
    );
  };

  const FooterPrivacy = ({ contactInfo }) => {
    return (
      <div className="footer-privacy">
        <span>{contactInfo.textRole} có thể gửi khiếu nại tại địa chỉ:</span> <br />
        <strong>Gosafe – Dịch vụ lái xe hộ uy tín trên toàn quốc</strong> <br />
        <InfoItem label="Địa chỉ">{contactInfo.address}</InfoItem>
        <br />
        <InfoItem label="Email">
          <a href={`mailto:${contactInfo.email}`}>
            <span>{contactInfo.email}</span>
          </a>
        </InfoItem>
        <br />
        <InfoItem label="Điện thoại">{contactInfo.phone}</InfoItem>
        <br />
        <InfoItem label="Giờ làm việc">{contactInfo.workingHours}</InfoItem>
      </div>
    );
  };

  const arrayMap = isUser ? arrayMapList : arrayMapListDriver;
  return (
    <>
      <Card className="width-privacy">
        <h1 className="title-privacy">
          <strong> CHÍNH SÁCH BẢO MẬT</strong>
        </h1>
        {arrayMap.map((item) => {
          return (
            <>
              <div>
                <h2>
                  <strong> {item?.title}</strong>
                </h2>
              </div>
              <div>{item?.subTitle}</div>
              {item?.dataSub?.map((item1, index1) => {
                return (
                  <ul key={index1}>
                    <li>{item1}</li>
                  </ul>
                );
              })}
              <div style={{ marginBottom: `8px` }}>{item?.sub3}</div>
              {item?.dataSub2?.map((item2, index2) => {
                return (
                  <ul key={index2}>
                    <li>{item2}</li>
                  </ul>
                );
              })}
              <div>{item?.sub4}</div>
              {item?.dataSub3?.map((item3, index3) => {
                return (
                  <ul key={index3}>
                    <li>{item3}</li>
                  </ul>
                );
              })}

              <div>{item?.sub5}</div>

              {item?.dataSub4?.map((item4, index4) => {
                return (
                  <ul key={index4}>
                    <li>{item4}</li>
                  </ul>
                );
              })}
              <div>{item?.sub6}</div>
              {item?.dataSub5?.map((item5, index5) => {
                return (
                  <ul key={index5}>
                    <li>{item5}</li>
                  </ul>
                );
              })}
            </>
          );
        })}
        <FooterPrivacy contactInfo={CONTACT_INFO} />
      </Card>
    </>
  );
}
