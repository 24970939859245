import { useState } from 'react';
import { IconButton, MenuItem, Popover } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import PaidIcon from '@mui/icons-material/Paid';
import {
  Edit,
  MoreVert,
  DeleteOutline,
  LockReset,
  HighlightOff,
  RestartAlt,
  AssignmentTurnedIn,
  Block,
  RemoveModerator,
} from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import _ from 'lodash';
import { ACTIONS } from '@/shared/constant/common';
import { MenuButtonTableProps } from '@/interfaces/table';

export const MenuButton = ({ actions, item, ...rest }: MenuButtonTableProps) => {
  const { id, userName, userId, params, isTextDelete, isTextBlock, isTextUnBlock, isTextEdit, isTextResetPassword } =
    item;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const lang = window.lang;
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleResetPassword = () => {
    if (rest && rest.onResetPassword && typeof id === 'string') {
      rest.onResetPassword(id, userName || userId);
      handleCloseMenu();
    }
  };

  const handleEdit = () => {
    if (rest && rest.onEdit && typeof id === 'string') {
      rest.onEdit(id, item);
      handleCloseMenu();
    }
  };

  const handleViewDetail = () => {
    if (rest && rest.onViewDetail && typeof id === 'string') {
      rest.onViewDetail(id, item);
      handleCloseMenu();
    }
  };

  const handleDelete = () => {
    if (rest && rest.onDelete) {
      rest.onDelete(id, item);
      handleCloseMenu();
    }
  };

  const handleResetStatus = () => {
    if (rest && rest.onResetStatus) {
      rest.onResetStatus(id);
      handleCloseMenu();
    }
  };

  const handleCancel = () => {
    if (rest && rest.onCancel) {
      rest.onCancel(id, item);
      handleCloseMenu();
    }
  };

  const handleApprove = () => {
    if (rest && rest.onApprove) {
      rest.onApprove(id);
      handleCloseMenu();
    }
  };

  const handleReject = () => {
    if (rest && rest.onReject) {
      rest.onReject(id);
      handleCloseMenu();
    }
  };

  const handlePaid = () => {
    if (rest && rest.onPaid) {
      rest.onPaid(id);
      handleCloseMenu();
    }
  };

  const handleBlock = () => {
    if (rest && rest.onBlock && typeof id === 'string') {
      rest.onBlock(id, item);
      handleCloseMenu();
    }
  };

  const handleUnBlock = () => {
    if (rest && rest.onUnBlock && typeof id === 'string') {
      rest.onUnBlock(id);
      handleCloseMenu();
    }
  };

  const actionInfo = [
    {
      action: ACTIONS.RESET_PASSWORD,
      label: isTextResetPassword ? isTextResetPassword : 'Đổi mật khẩu',
      icon: <LockReset sx={{ mr: 2 }} />,
      onClick: handleResetPassword,
    },
    {
      action: ACTIONS.EDIT,
      label: isTextEdit ? isTextEdit : 'Chỉnh sửa',
      disabled: !!item?.isEdit,
      icon: <Edit sx={{ mr: 2 }} />,
      onClick: handleEdit,
    },
    {
      action: ACTIONS.VIEW_DETAIL,
      label: 'Xem chi tiết',
      icon: <RemoveRedEyeIcon sx={{ mr: 2 }} />,
      onClick: handleViewDetail,
    },
    {
      action: ACTIONS.DELETE,
      label: isTextDelete ? isTextDelete : 'Xóa',
      disabled: !!item?.isDelete,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDelete,
      sx: { color: 'error.main' },
    },
    {
      action: ACTIONS.CANCEL,
      label: 'Hủy đơn',
      disabled: !!item.isCancel,
      icon: <HighlightOff sx={{ mr: 2 }} />,
      onClick: handleCancel,
      sx: { color: 'error.main' },
    },
    {
      action: ACTIONS.RE_STATUS,
      label: lang.labelInput.reStatus,
      disabled: !!item?.isReStatus,
      icon: <RestartAlt sx={{ mr: 2 }} />,
      onClick: handleResetStatus,
      sx: { color: 'error.main' },
    },
    {
      action: ACTIONS.APPROVE,
      label: lang.labelInput.approve,
      disabled: !!item?.isApprove,
      icon: <AssignmentTurnedIn sx={{ mr: 2 }} />,
      onClick: handleApprove,
    },
    {
      action: ACTIONS.REJECT,
      label: lang.button.actions.reject,
      disabled: !!item?.isReject,
      icon: <BlockIcon sx={{ mr: 2 }} />,
      onClick: handleReject,
      sx: { color: 'error.main' },
    },
    {
      action: ACTIONS.BLOCK,
      label: isTextBlock ? isTextBlock : lang.labelInput.block,
      disabled: !!item?.isBlock,
      icon: <Block sx={{ mr: 2 }} />,
      onClick: handleBlock,
      sx: { color: 'error.main' },
    },
    {
      action: ACTIONS.UN_BLOCK,
      label: isTextUnBlock ? isTextUnBlock : lang.labelInput.block,
      disabled: !!item?.isBlock,
      icon: <RemoveModerator sx={{ mr: 2 }} />,
      onClick: handleUnBlock,
      sx: { color: 'var(--color-blue)' },
    },
    {
      action: ACTIONS.PAID,
      label: 'Xác nhận thanh toán',
      disabled: !!item?.isPaid,
      icon: <PaidIcon sx={{ mr: 2 }} />,
      onClick: handlePaid,
      sx: { color: 'var(--color-blue)' },
    },
  ];

  return (
    <>
      <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
        <MoreVert />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {_.isArray(actions) &&
          actions.map((action, index) => {
            const info = actionInfo.find((item) => item.action === action);
            return info ? (
              <MenuItem
                key={index}
                className="menu-action"
                onClick={info.onClick}
                sx={info.sx}
                disabled={info.disabled}
              >
                {info.icon}
                {info.label}
              </MenuItem>
            ) : null;
          })}
      </Popover>
    </>
  );
};
