import { Box, Grid, Paper, FormControl, Container, useMediaQuery } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { schemaForget } from '@/common/Schema';
import { CONST_ROUTER } from '@/shared/constant/common';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceData, postForgetPass } from '@/redux/slices/auth';
import LogoDriver from '../../asset/img/logoSafe.png';
import { InputForm } from '@/common/Form';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { CustomBackdrop } from '@/components/CustomBackdrop';

export default function ForgetPass() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const authData = useSelector(authSliceData);
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaForget(lang)),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data): Promise<void> => {
    const params = {
      data: {
        email: data?.email.trim(),
        type: 1,
      },
      callBackSuccess: (data) => {
        navigate(CONST_ROUTER.LOGIN);
        showSuccessToast(lang.auth.successForget);
      },
      callBackError: (error) => {
        showErrorToast(error);
      },
    };
    dispatch(postForgetPass(params));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const handleBack = () => {
    reset();
    navigate(CONST_ROUTER.LOGIN);
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Box mt={8}>
          <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={12}>
              <Box mt={1} className="container-logo">
                <img src={LogoDriver} alt="" className="logo-forget" />
                <strong className="text-hire-driver">{lang.auth.hireDriver}</strong>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              className="background-login"
              component={Paper}
              elevation={6}
              square
              sx={{ ...(isMobile && { boxShadow: 'none' }) }}
            >
              <Box
                m={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box mt={1}>
                  <FormControl variant="outlined" fullWidth={true}>
                    <strong className="text-forget">{lang.auth.forgetPass}</strong>
                    <p className="m-8">{lang.auth.emailForget}</p>
                    <strong>{lang.auth.userName}</strong>
                    <InputForm
                      control={control}
                      name="email"
                      type="text"
                      size="small"
                      placeholder={lang.auth.userName}
                      inputProps={{ autoFocus: true }}
                      onKeyDown={handleKeyDown}
                      className="input-login margin-input-login"
                      errors={errors}
                    />
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <ButtonCustom
                    fullWidth={false}
                    onClick={handleSubmit(onSubmit)}
                    title={lang.auth.continue}
                    variant="contained"
                  />
                </Box>
                <Box m={1} className="forget-pass" onClick={handleBack}>
                  <strong>{lang.auth.backLogin}</strong>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <CustomBackdrop open={authData?.loadingPostForgetPass} />
    </>
  );
}
