import { TYPE_PATH } from '@/shared/utils/common';
import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export interface MediaDisplayProps {
  src: string;
  alt?: string;
  onDelete?: () => void;
  onClick?: () => void;
  className?: string;
  type?: string;
  selectedFiles?: File;
}

function MediaDisplay({ src, alt, onDelete, onClick, className, type, selectedFiles }: MediaDisplayProps) {
  const fileType = selectedFiles && selectedFiles.type;

  return (
    <div className="imageContainer">
      {(fileType && fileType.startsWith(TYPE_PATH.VIDEO)) || type === TYPE_PATH.VIDEO ? (
        <video className={className || 'img-payment-order'} src={src} controls onClick={onClick} />
      ) : (
        <img className={className || 'img-payment-order'} src={src} alt={alt} onClick={onClick} />
      )}

      {onDelete && (
        <IconButton className="delete-button-paid" onClick={onDelete}>
          <Clear />
        </IconButton>
      )}
    </div>
  );
}

export default MediaDisplay;
