import React, { useState, useEffect, useRef } from 'react';
import { TextField, CircularProgress, IconButton, Menu, MenuItem, Grid, FormControl } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { LocationOn } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { END, STOP } from '@/shared/constant/text';
import { googleMapSliceData, putGoogleMapApi } from '@/redux/slices/googleMapApi';

const GoogleMapAutoComplete = ({
  id,
  name,
  control,
  errors,
  setValue,
  clearErrors,
  gridWidth,
  type,
  index,
  getValues,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const dispatch = useDispatch<any>();
  const initialFieldValue = useRef<any>();
  const searchData = useSelector(googleMapSliceData);

  useEffect(() => {
    initialFieldValue.current = getValues(name);
  }, [getValues, name]);

  const handleSend = (inputValue) => {
    setIsLoading(true);
    const payload = {
      params: {
        textSearch: inputValue,
      },
      callbackSuccess: (response) => {
        const array = response?.data?.locations || [];
        const options = array?.map((item, index) => {
          return {
            value: index + 1,
            label: item?.address,
            name: item?.name,
            location: item?.location,
          };
        });
        setOptions(options);
        setIsLoading(false);

        setAnchorEl(document.getElementById(`demo-positioned-button-${id}`));
      },
      callbackError: () => {
        setOptions([]);
        setIsLoading(false);

        setAnchorEl(document.getElementById(`demo-positioned-button-${id}`));
      },
    };
    dispatch(putGoogleMapApi(payload));
  };

  const handleClear = (field) => {
    if (type === END) {
      setValue(`pointEnd.${index}.location`, '');
    } else {
      setValue(`pointStopping.${index}.location`, '');
    }
    field.onChange('');
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => () => {
    if (type === END) {
      setValue(`pointEnd.${index}.location`, JSON.stringify(option?.location));
      clearErrors(`pointEnd.${index}.location`);
    } else {
      setValue(`pointStopping.${index}.location`, JSON.stringify(option?.location));
      clearErrors(`pointStopping.${index}.location`);
    }
    setValue(name, option.label || option.name);
    setAnchorEl(null);
  };

  const handleKeyDown = (e, value) => {
    if (e.key === 'Enter') {
      handleSend(value);
    }
  };

  return (
    <Grid item xs={12} display="flex" flexWrap="wrap">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              <TextField
                {...field}
                placeholder={type === END ? 'Vui lòng nhập vào điểm đến' : 'Vui lòng nhập vào điểm dừng'}
                id={`demo-positioned-button-${id}`}
                size="small"
                className="input-location"
                value={field?.value}
                onKeyDown={(e) => handleKeyDown(e, field.value)}
                onChange={(e) => {
                  field.onChange(e);
                  if (!e.target.value) {
                    setOptions([]);
                  } else if (initialFieldValue && initialFieldValue.current !== e.target.value && initialFieldValue) {
                    initialFieldValue.current = e.target.value;
                    if (field.value !== initialFieldValue.current) {
                      switch (type) {
                        case 'end':
                          setValue(`pointEnd.0.location`, '');
                          break;
                        default:
                          setValue(`pointStopping.${index}.location`, '');
                          break;
                      }
                    }
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          {field.value && (
                            <>
                              <IconButton onClick={() => handleSend(field.value)} className="icon-cursor color-blue">
                                <SendIcon />
                              </IconButton>
                              <IconButton onClick={() => handleClear(field)} className="icon-cursor color-red">
                                <ClearIcon />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ),
                }}
                fullWidth
                error={Boolean(errors[name])}
              />
            </>
          );
        }}
      />
      <FormControl fullWidth>
        <Menu
          id={`demo-positioned-menu-${id}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          className="list-location"
          onClose={handleCloseMenu}
          sx={{ width: '100%', top: '5.3%' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {options.length > 0 ? (
            options.map((option: any, index) => (
              <MenuItem
                key={index}
                onClick={handleMenuItemClick(option)}
                className="location-menu"
                sx={{ width: `${gridWidth}px` }}
              >
                <Grid item xs={12} display="flex" alignItems="center">
                  <LocationOn className="icon-location" />
                  <Grid item xs={12} display="flex" flexWrap="wrap">
                    <Grid item xs={12}>
                      <strong>{option?.name}</strong>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      <span>{option?.label}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          ) : (
            <MenuItem key={index} className="location-menu" sx={{ width: `${gridWidth}px` }}>
              <Grid item xs={12} display="flex" alignItems="center">
                <LocationOn className="icon-location" />
                <Grid item xs={12} display="flex" flexWrap="wrap">
                  <Grid
                    item
                    xs={12}
                    sx={{
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    <span>Không tìm thấy kết quả</span>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          )}
        </Menu>
      </FormControl>
    </Grid>
  );
};

export default GoogleMapAutoComplete;
