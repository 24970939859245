import { SelectSearch } from '@/common/Form';
import MapComponent from '@/components/MapComponent';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { BiSearch } from 'react-icons/bi';
import { BsFillEraserFill } from 'react-icons/bs';
import { FormControl } from '@mui/base';
import { useEffect, useState } from 'react';
import { RestartAlt } from '@mui/icons-material';
import SwitchInputForm from '@/common/Form/switchButton';
import { driverData, getDriverGoogleMap } from '@/redux/slices/driver';
import { useDispatch, useSelector } from 'react-redux';
import { API_KEY, TIME_REQUEST, URL_GOOGLEMAP } from '@/shared/config/setting';
import { statusDriver } from '@/shared/options';
export const DriverActive = () => {
  const lang = window.lang;
  const [defaultLocation, setDefaultLocation] = useState<any>();
  const schema = yup.object().shape({
    driverActive: yup.string().nullable(),
    isStatus: yup.boolean(),
  });
  const dispatch = useDispatch<any>();
  const driverDataSelector = useSelector(driverData);
  const [totalDriver, setTotalDriver] = useState<number>(0);
  const IS_FREE_TIME = '1';
  const IS_TRIP = '2';
  const IS_NEAR_TRIP = '3';
  const IS_ALL_DRIVER = '4';
  const [isStatusDriver, setIsStatusDriver] = useState<string | undefined>(IS_ALL_DRIVER);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      driverActive: '',
      isStatus: false,
    },
  });
  const status = watch('isStatus');
  const { listDriverGooogleMap, loadingListDriverGoogleMap } = driverDataSelector;
  const [optionsDriver, setOptionsDriver] = useState<any>();

  useEffect(() => {
    dispatch(getDriverGoogleMap());
  }, []);

  useEffect(() => {
    setOptionsDriver(listDriverGooogleMap);
    setTotalDriver(listDriverGooogleMap?.length);
  }, [listDriverGooogleMap]);

  useEffect(() => {
    if (isStatusDriver !== IS_ALL_DRIVER) {
      const dataObject = {
        driverActive: isStatusDriver,
      };
      const totalDriver = filterDrivers(dataObject, listDriverGooogleMap);
      setTotalDriver(totalDriver?.length || 0);
    } else {
      setTotalDriver(listDriverGooogleMap?.length);
    }
  }, [isStatusDriver]);

  const enumTotalDriver = (isDriver) => {
    const checkStatus = {
      [IS_ALL_DRIVER]: 'Tổng số tài xế đang hoạt động',
      [IS_FREE_TIME]: 'Tổng số tài xế đang rảnh',
      [IS_NEAR_TRIP]: 'Tổng số tài xế sắp đến điểm kết thúc',
      [IS_TRIP]: 'Tổng số tài xế đang trong hành trình',
    };
    return checkStatus[isDriver];
  };

  useEffect(() => {
    let id;
    if (status) {
      setValue('driverActive', '');
      setIsStatusDriver(IS_ALL_DRIVER);
      dispatch(getDriverGoogleMap());
      id = setInterval(() => {
        dispatch(getDriverGoogleMap());
      }, TIME_REQUEST);
    } else {
      clearInterval(id);
    }
    return () => {
      clearInterval(id);
    };
  }, [status]);

  const updatelocation = () => {
    reset();
    setIsStatusDriver(IS_ALL_DRIVER);
    dispatch(getDriverGoogleMap());
  };

  const filterDrivers = (data, listDriverGooogleMap) => {
    if (!listDriverGooogleMap?.length) return [];
    return listDriverGooogleMap.filter((item) => {
      switch (data?.driverActive) {
        case IS_FREE_TIME:
          return !item?.isBusy;
        case IS_NEAR_TRIP:
          return item?.isDriverNearDestination;
        case IS_TRIP:
          return item?.isBusy;
        default:
          return false;
      }
    });
  };

  const onSubmit = (data) => {
    if (data.driverActive) {
      setIsStatusDriver(data?.driverActive);
      const filteredDrivers = filterDrivers(data, listDriverGooogleMap);
      setOptionsDriver(filteredDrivers);
    }
  };

  const handleResetFilter = () => {
    reset();
    setIsStatusDriver(IS_ALL_DRIVER);
    setOptionsDriver(listDriverGooogleMap);
  };
  const ButtonSearch = ({ submit, resetSearch }) => {
    return (
      <Grid sx={{ display: 'flex', gap: '1rem' }}>
        <ButtonCustom
          icon={<BiSearch />}
          color="success"
          title={lang.driverManagement.search}
          variant="contained"
          onClick={submit}
          fullWidth={false}
          sx={{ padding: '8px 16px', minWidth: '155px' }}
        />
        <ButtonCustom
          icon={<BsFillEraserFill />}
          color="error"
          title={lang.driverManagement.removeSearch}
          variant="contained"
          onClick={resetSearch}
          fullWidth={false}
          sx={{ padding: '8px 16px', minWidth: '155px' }}
        />
      </Grid>
    );
  };
  return (
    <>
      <Grid container my={2} justifyContent="space-between" display="flex">
        <Grid item className="driver-active">
          <FormControl style={{ flexGrow: 1 }}>
            <SelectSearch
              label="Trạng thái tài xế"
              control={control}
              name="driverActive"
              options={statusDriver}
              errors={errors}
              loadingOption={loadingListDriverGoogleMap}
            />
          </FormControl>

          <ButtonSearch submit={handleSubmit(onSubmit)} resetSearch={handleResetFilter} />
        </Grid>

        <Grid item display="flex" alignItems="center" justifyContent="flex-start" maxHeight="40.5px" gap={2}>
          <SwitchInputForm control={control} name="isStatus" size="small" errors={errors} checked={watch('isStatus')} />
          <ButtonCustom
            className="button-width"
            title={lang.driverManagement.updatePosition}
            variant="contained"
            onClick={() => updatelocation()}
            icon={<RestartAlt />}
            fullWidth={false}
            sx={{ padding: '8px 16px' }}
            disabled={watch('isStatus')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="space-between" my={1}>
        <strong style={{ color: 'blue' }}>
          {enumTotalDriver(isStatusDriver)}: {totalDriver}
        </strong>
      </Grid>

      {listDriverGooogleMap && (
        <Grid item xs={12}>
          <MapComponent
            googleMapURL={`${URL_GOOGLEMAP}js?key=${API_KEY}`}
            loadingElement={<div style={{ height: `80%` }} />}
            containerElement={<div style={{ height: `70vh`, margin: `auto`, border: '2px solid black' }} />}
            mapElement={<div style={{ height: `100%` }} />}
            listDriverMap={optionsDriver || []}
            defaultLocation={defaultLocation}
            setDefaultLocation={setDefaultLocation}
            loadingListDriverGoogleMap={loadingListDriverGoogleMap}
          />
        </Grid>
      )}
    </>
  );
};
