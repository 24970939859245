import * as yup from 'yup';
import {
  passwordRegExp,
  addressRegExp,
  notFullSpace,
  fullnameRegExp,
  usernameRegExp,
  phonenumberReg,
  fullnameRegExpDriver,
} from '@/shared/constant/common';
import {
  googleEmailRegExp,
  passwordNonWhitespacePattern,
  passwordRegExpLogin,
  regNameVehicleClass,
} from '@/shared/utils/regex';
import { compareDate, convertToYYYY, maxYear } from '@/shared/utils/common';
import {
  FROM_DATE,
  LATEST_MONTH_QUARTER_1,
  LATEST_MONTH_QUARTER_2,
  LATEST_MONTH_QUARTER_3,
  MINDATE,
  MONTH_YEAR,
  QUARTER,
  QUARTER_1,
  QUARTER_2,
  QUARTER_3,
  YEAR,
} from '@/shared/constant/text';
import { endOfYear, subYears } from 'date-fns';

// NEW SCHEMA HIRE A DRIVER

export const schemaLoginDriver = (lang) =>
  yup.object().shape({
    username: yup
      .mixed()
      .test('username-required', lang.auth.userNameRequired, function (value: any) {
        if (!value) return false; // Nếu email trống, trả về false để kích hoạt thông báo lỗi
        return true;
      })
      .test('username-is-email', lang.auth.wrongEmailFormat, function (value: any) {
        return googleEmailRegExp.test(value);
      }),
    password: yup
      .string()
      .required(lang.auth.passwordRequired)
      .min(6, lang.auth.minPass)
      .max(20, lang.auth.maxPass)
      .matches(passwordRegExpLogin, lang.auth.passwordInvalid)
      .matches(passwordNonWhitespacePattern, lang.auth.passwordInvalid),
  });

export const schemaForget = (lang) =>
  yup.object().shape({
    email: yup
      .mixed()
      .required(lang.auth.userNameRequired)
      .test('username-is-email', lang.auth.emailvalid, function (value: any) {
        if (!value) return false;
        if (!googleEmailRegExp.test(value)) return false;

        const [username] = value.split('@');
        if (/^\d+$/.test(username)) {
          return this.createError({ message: 'Email không hợp lệ!' });
        }

        return true;
      }),
  });

export const schemaUpdatePassword = yup.object().shape({
  new_password: yup
    .string()
    .required('Mật khẩu mới không được để trống')
    .min(6, 'Mật khẩu mới phải có tối thiểu 6 kí tự')
    .max(20, 'Mật khẩu mới không được vượt quá 20 ký tự')
    .matches(passwordRegExp, 'Mật khẩu không hợp lệ!')
    .test('notEqual', 'Mật khẩu mới không được trùng với mật khẩu cũ', function (value) {
      const { password } = this.parent;
      return value !== password;
    }),
  password: yup
    .string()
    .required('Mật khẩu cũ không được để trống')
    .min(6, 'Mật khẩu cũ phải có ít nhất 6 kí tự!')
    .max(20, 'Mật khẩu cũ không được vượt quá 20 ký tự')
    .matches(passwordRegExp, 'Mật khẩu cũ không được chứa khoảng trắng!'),
  new_password_confirmed: yup
    .string()
    .required('Xác nhận mật khẩu không được để trống')
    .max(20, 'Xác nhận mật khẩu không được vượt quá 20 ký tự')
    .oneOf([yup.ref('new_password')], 'Xác nhận mật khẩu phải trùng với mật khẩu mới '),
});

export const SchemaEditUser = yup.object().shape({
  bio: yup.string().nullable(),
  street: yup
    .string()
    .required('Địa chỉ không được để trống')
    .trim()
    .matches(addressRegExp, 'Địa chỉ không hợp lệ')
    .matches(notFullSpace, 'Địa chỉ không hợp lệ'),
  email: yup
    .string()
    .trim()
    .nullable()
    .max(60, 'Email có tối đa 60 kí tự')
    .test('username-is-email', 'Email không hợp lệ!', function (value) {
      if (!value) return false;
      if (!googleEmailRegExp.test(value)) return false;

      const [username] = value.split('@');
      if (/^\d+$/.test(username)) {
        return this.createError({ message: 'Email không hợp lệ!' });
      }

      return true;
    }),
  firstName: yup
    .string()
    .trim()
    .required('Họ và tên đệm không được để trống')
    .min(1, 'Họ và tên đệm phải có tối thiểu 1 kí tự')
    .max(30, 'Họ và tên đệm có tối đa 30 kí tự')
    .matches(fullnameRegExp, 'Họ không hợp lệ'),
  lastName: yup
    .string()
    .trim()
    .required('Tên không được để trống')
    .min(1, 'Tên phải có tối thiểu 1 kí tự')
    .max(30, 'Tên có tối đa 30 kí tự')
    .matches(fullnameRegExp, 'Tên không hợp lệ'),
  date_of_birth: yup
    .date()
    .max(new Date(maxYear(), 0, 1), 'Ngày sinh không được chọn là tương lai')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Ngày sinh không hợp lệ'),
  gender: yup.number().nullable(),
  phone_number: yup
    .string()
    .trim()
    .required('Số điện thoại không được để trống')
    .matches(/^\s*[+\d\s]+\s*$/, 'Số điện thoại không hợp lệ!')
    .matches(/^[0-9]+$/, 'Số điện thoại không hợp lệ!')
    .matches(/^0/, 'Số điện thoại không hợp lệ!')
    .min(10, 'Số điện thoại phải có ít nhất 10 chữ số')
    .max(11, 'Số điện thoại không được vượt quá 11 chữ số'),
  provinces: yup.number().required('Tỉnh/Thành phố không được để trống').typeError('Tỉnh/Thành phố không hợp lệ'),
  districts: yup.number().required('Quận/Huyện không được để trống').typeError('Quận/Huyện không hợp lệ'),
  wards: yup.number().required('Phường/Xã không được để trống').typeError('Phường/Xã không hợp lệ'),
});

export const schemaAddcustomer = (lang) =>
  yup.object().shape({
    provinceId: yup.string().nullable().trim(),
    surnameAndMiddleName: yup
      .string()
      .nullable()
      .trim()
      .required(lang.managementCustomer.firstNameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName),
    lastName: yup
      .string()
      .nullable()
      .trim()
      .required(lang.managementCustomer.nameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName),
    phoneNumber: yup
      .string()
      .trim()
      .required(lang.managementCustomer.phoneNumberRequired)
      .matches(phonenumberReg, lang.schema.phoneNumberInvalid),
    dob: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(lang.managementCustomer.dateValid)
      .max(new Date(new Date().getFullYear() - 13, 11, 31), 'Năm sinh phải đủ 13 tuổi')
      .min(MINDATE, `Năm sinh không được chọn trước năm ${MINDATE.getFullYear()}`),
    status: yup.number().nullable().required(lang.managementCustomer.statusRequired),
    email: yup
      .string()
      .trim()
      .nullable()
      .required(lang.managementCustomer.emailRequired)
      .max(60, lang.driverManagement.maxEmail)
      .test('username-is-email', lang.auth.emailvalid, function (value) {
        if (!value) return false;
        if (!googleEmailRegExp.test(value)) return false;

        const [username] = value.split('@');
        if (/^\d+$/.test(username)) {
          return this.createError({ message: 'Email không hợp lệ!' });
        }

        return true;
      }),
    gender: yup.number().nullable(),
  });

export const schemaAddTrip = (lang) =>
  yup.object().shape({
    driver: yup.string().nullable(),
    customer: yup.string().required('Khách hàng không được để trống'),
    departure: yup.object().shape({
      id: yup.string().nullable(),
      address: yup.string(),
      location: yup.string().required('Điểm đi không được để trống, vui lòng chọn điểm đi.'),
    }),
    stop: yup.object().shape({
      id: yup.string().nullable(),
      address: yup.string(),
      location: yup.string().test('test', 'Điểm dừng không được để trống, vui lòng chọn điểm dừng', function (value) {
        const { address } = this.parent;
        if (address) return !!value;
        return true;
      }),
    }),
    destination: yup.object().shape({
      id: yup.string().nullable(),
      address: yup.string(),
      location: yup.string().test('test', 'Điểm đến không được để trống, vui lòng chọn điểm đến.', function (value) {
        const { address } = this.parent;
        if (address) return !!value;
        return true;
      }),
    }),
    price: yup.string().nullable(),
    payment: yup.string().required('Hình thức thanh toán không được để trống'),
    vehicle: yup.string().required('Hạng xe không được để trống'),
    distance: yup.string().nullable(),
  });

export const schemaAddAccount = (lang) =>
  yup.object().shape({
    userName: yup
      .string()
      .trim()
      .required(lang.schema.name.nameRequired)
      .matches(usernameRegExp, lang.schema.name.wrongUserName),
    surnameAndMiddleName: yup
      .string()
      .nullable()
      .required(lang.managementCustomer.firstNameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName)
      .trim()
      .test('no-consecutive-whitespace', lang.schema.fullName.twoBackSpace, (value) => {
        if (!value) return true;
        return !/\s\s/.test(value);
      })
      .test('no-leading-whitespace', lang.schema.fullName.firstBackSpace, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    lastName: yup
      .string()
      .nullable()
      .required(lang.managementCustomer.nameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName)
      .trim()
      .test('no-consecutive-whitespace', lang.schema.fullName.twoBackSpace, (value) => {
        if (!value) return true;
        return !/\s\s/.test(value);
      })
      .test('no-leading-whitespace', lang.schema.fullName.firstBackSpace, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .required('Số điện thoại không được để trống')
      .matches(phonenumberReg, lang.schema.phoneNumberInvalid),
    dob: yup
      .date()
      .max(subYears(new Date(), 18), 'Năm sinh phải đủ 18 tuổi')
      .min(MINDATE, `Năm sinh không được chọn trước năm ${MINDATE.getFullYear()}`)
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(lang.managementCustomer.dateValid),
    status: yup.number().nullable().required(lang.managementCustomer.statusRequired),
    email: yup
      .string()
      .trim()
      .nullable()
      .required(lang.managementCustomer.emailRequired)
      .max(60, lang.driverManagement.maxEmail)
      .test('username-is-email', lang.auth.emailvalid, function (value) {
        if (!value) return false;
        if (!googleEmailRegExp.test(value)) return false;

        const [username] = value.split('@');
        if (/^\d+$/.test(username)) {
          return this.createError({ message: 'Email không hợp lệ!' });
        }

        return true;
      }),
    address: yup.string().nullable().trim(),
    gender: yup.number().nullable(),
    role: yup.number().nullable().required(lang.managementCustomer.addressRequire),
    avatar: yup
      .string()
      .nullable()
      .test('fileType', lang.driverManagement.imageFileType, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedFormats.includes(value.type)) {
          return createError({ path, message: lang.driverManagement.imageFileType });
        }
        return true;
      })
      .test('fileSize', lang.driverManagement.imageFileSize, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (value.size > maxSize) {
          return createError({ path, message: lang.driverManagement.imageFileSize });
        }
        return true;
      }),
  });

export const schemaVehicleClass = (lang) =>
  yup.object().shape({
    name: yup
      .string()
      .nullable()
      .trim()
      .required(lang.vehicleClass.nameRequired)
      .min(3, lang.vehicleClass.minName)
      .max(200, lang.vehicleClass.maxName)
      .matches(regNameVehicleClass, lang.vehicleClass.validName),
    about: yup
      .string()
      .required(lang.vehicleClass.aboutRequired)
      .trim()
      .max(500, 'Mô tả không được vượt quá 500 kí tự'),
    status: yup.string().nullable().required(lang.managementCustomer.statusRequired),
  });

export const schemaAddDriver = (lang, image1, image2, image3, image4, avatar) =>
  yup.object().shape({
    surnameAndMiddleName: yup
      .string()
      .required(lang.driverManagement.firstNameRequired)
      .matches(fullnameRegExpDriver, lang.driverManagement.nameValid)
      .min(1, lang.driverManagement.minName)
      .max(60, lang.driverManagement.maxName)
      .trim(),
    lastName: yup
      .string()
      .required(lang.driverManagement.nameRequired)
      .matches(fullnameRegExpDriver, lang.driverManagement.nameValid)
      .min(1, lang.driverManagement.minName)
      .max(60, lang.driverManagement.maxName)
      .trim(),
    phoneNumber: yup
      .string()
      .trim()
      .required(lang.driverManagement.phoneNumberRequired)
      .matches(phonenumberReg, lang.schema.phoneNumberInvalid),
    dob: yup
      .date()
      .min(MINDATE, `Năm sinh không được chọn trước năm ${MINDATE.getFullYear()}`)
      .max(subYears(new Date(), 18), 'Năm sinh phải đủ 18 tuổi')
      .nullable()
      .required(lang.driverManagement.dateRequired)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(lang.driverManagement.dateValid),
    status: yup.number().nullable().required(lang.driverManagement.statusRequired),
    email: yup
      .string()
      .trim()
      .nullable()
      .required(lang.driverManagement.emailRequired)
      .max(60, lang.driverManagement.maxEmail)
      .test('username-is-email', lang.auth.emailvalid, function (value) {
        if (!value) return false;
        if (!googleEmailRegExp.test(value)) return false;

        const [username] = value.split('@');
        if (/^\d+$/.test(username)) {
          return this.createError({ message: 'Email không hợp lệ!' });
        }

        return true;
      }),
    address: yup.string().nullable().required(lang.driverManagement.addressRequired).trim(),
    gender: yup.number().nullable().required('Giới tính không được để trống'),
    avatar: yup
      .string()
      .nullable()
      .test('fileType', lang.driverManagement.imageFileType, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedFormats.includes(value.type)) {
          return createError({ path, message: lang.driverManagement.imageFileType });
        }
        return true;
      })
      .test('fileSize', lang.driverManagement.imageFileSize, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (value.size > maxSize) {
          return createError({ path, message: lang.driverManagement.imageFileSize });
        }
        return true;
      }),
    frontIdCheck: yup.string().test('required-condition', lang.driverManagement.idFrontRequired, function (value) {
      return image1 ? true : !!value;
    }),
    backIdCheck: yup.string().test('required-condition', lang.driverManagement.idBackRequired, function (value) {
      return image2 ? true : !!value;
    }),
    frontLicencseCheck: yup
      .string()
      .test('required-condition', lang.driverManagement.idFrontRequired, function (value) {
        return image3 ? true : !!value;
      }),
    backLicencseCheck: yup.string().test('required-condition', lang.driverManagement.idBackRequired, function (value) {
      return image4 ? true : !!value;
    }),
  });

const createValidationFunctionStatistical = (dateFilter, errorMessage, lang) => {
  return yup
    .date()
    .nullable()
    .min(new Date(2000, 0, 1), lang.schema.day.timeRequired)
    .max(new Date(), lang.schema.day.timeRequired)
    .test('dateFilter', errorMessage, (value, context) => {
      const { parent } = context;
      if (dateFilter && parent?.dateFilter === dateFilter) {
        return !!value;
      }
      return true;
    });
};

const yearSchemaStatistical = (lang) => createValidationFunctionStatistical(YEAR, lang.timekeeping.yearRequired, lang);
const monthYearSchemaStatistical = (lang) =>
  createValidationFunctionStatistical(MONTH_YEAR, lang.timekeeping.monthYearRequired, lang);
const quarterYearSchemaStatistical = (lang) =>
  createValidationFunctionStatistical(QUARTER, lang.timekeeping.yearRequired, lang);

export const schemaFormAccount = (lang) =>
  yup.object().shape({
    dateFilter: yup.string().nullable(),
    text: yup.string().nullable(),
    roles: yup.string().nullable(),
    status: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDate: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        return parent?.endDate ? !!value : true;
      })
      .typeError(lang.schema.order.dateError),
    endDate: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDate), new Date(value)) : true;
      })
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        return parent?.startDate ? !!value : true;
      })
      .typeError(lang.schema.order.dateError),
  });

export const schemaFormTrip = (lang) =>
  yup.object().shape({
    driver: yup.string().nullable(),
    customer: yup.string().nullable(),
    status: yup.string().nullable(),
    payment: yup.string().nullable(),
    spend: yup.string().nullable(),
    originalMoneyId: yup.string().nullable(),
    incomingMoneyId: yup.string().nullable(),
    typeRevenue: yup.string().nullable(),
    money: yup.string().nullable(),
    typeProduct: yup.string().nullable(),
    dateFilter: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .typeError(lang.schema.dateError),
    endDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDay), new Date(value)) : true;
      })
      .typeError(lang.schema.order.dateError),
  });

export const schemaEvalutation = (lang) =>
  yup.object().shape({
    driver: yup.string().nullable(),
    customer: yup.string().nullable(),
    vote: yup.string().nullable(),
    originalMoneyId: yup.string().nullable(),
    incomingMoneyId: yup.string().nullable(),
    dateFilter: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .typeError(lang.schema.dateError),
    endDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDay), new Date(value)) : true;
      })
      .typeError(lang.schema.dateError),
  });

export const schemaTripStatictis = (lang) =>
  yup.object().shape({
    driver: yup.string().nullable(),
    customer: yup.string().nullable(),
    status: yup.string().nullable(),
    typePayment: yup.string().nullable(),
    vote: yup.string().nullable(),
    originalMoneyId: yup.string().nullable(),
    incomingMoneyId: yup.string().nullable(),
    dateFilter: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDay: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .typeError(lang.schema.dateError),
    endDay: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDay), new Date(value)) : true;
      })
      .typeError(lang.schema.dateError),
  });

export const schemaRevenueStatistics = (lang) =>
  yup.object().shape({
    address: yup.string().nullable(),
    payment: yup.string().nullable(),
    dateFilter: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .typeError(lang.schema.dateError),
    endDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDay), new Date(value)) : true;
      })
      .test('endDate', 'Thống kê khoảng ngày tối đa 3 tháng', function (value, { parent }) {
        const startDay = parent.startDay;
        const maxEndDay = new Date(startDay);
        maxEndDay.setDate(maxEndDay.getDate() + 93);

        return !value || value <= maxEndDay;
      })
      .typeError(lang.schema.dateError),
  });

export const schemaHistoryDriver = (lang) =>
  yup.object().shape({
    addressStart: yup.string().nullable(),
    addressEnd: yup.string().nullable(),
    text: yup.string().nullable(),
    dateFilter: yup.string().nullable(),
    year: yearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    monthYear: monthYearSchemaStatistical(lang)
      .test('monthYear', 'Không đúng định dạng', function (value) {
        const { parent } = this;
        if (parent?.monthYear === 'Invalid Date') {
          return false;
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    quarterYear: quarterYearSchemaStatistical(lang).typeError(lang.schema.day.timeRequired),
    quarter: yup
      .string()
      .nullable()
      .test('dateFilter', 'Quý không được để trống', function (value) {
        const { parent } = this;
        if (parent?.dateFilter === QUARTER) {
          return !!value;
        }
        return true;
      })
      .test('dateFilter', 'Quý không hợp lệ', function (value) {
        const { parent } = this;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear().toString();
        const yearConvert = convertToYYYY(parent?.quarterYear);

        if (parent?.dateFilter === QUARTER && yearConvert === currentYear) {
          switch (true) {
            case currentMonth <= LATEST_MONTH_QUARTER_1:
              return value === QUARTER_1;
            case currentMonth <= LATEST_MONTH_QUARTER_2:
              return value === QUARTER_1 || value === QUARTER_2;
            case currentMonth <= LATEST_MONTH_QUARTER_3:
              return value === QUARTER_1 || value === QUARTER_2 || value === QUARTER_3;
            default:
              return true;
          }
        }
        return true;
      })
      .typeError(lang.schema.day.timeRequired),
    startDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .typeError(lang.schema.dateError),
    endDay: yup
      .date()
      .nullable()
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        if (parent?.dateFilter === FROM_DATE) {
          return !!value;
        }
        return true;
      })
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDay), new Date(value)) : true;
      })
      .typeError(lang.schema.order.dateError),
  });

export const schemaProfile = (lang) =>
  yup.object().shape({
    surnameAndMiddleName: yup
      .string()
      .nullable()
      .required(lang.managementCustomer.firstNameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName)
      .trim()
      .test('no-consecutive-whitespace', lang.schema.fullName.twoBackSpace, (value) => {
        if (!value) return true;
        return !/\s\s/.test(value);
      })
      .test('no-leading-whitespace', lang.schema.fullName.firstBackSpace, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    lastName: yup
      .string()
      .nullable()
      .required(lang.managementCustomer.nameRequired)
      .matches(fullnameRegExp, lang.managementCustomer.nameValid)
      .min(1, lang.managementCustomer.minName)
      .max(60, lang.managementCustomer.maxName)
      .trim()
      .test('no-consecutive-whitespace', lang.schema.fullName.twoBackSpace, (value) => {
        if (!value) return true;
        return !/\s\s/.test(value);
      })
      .test('no-leading-whitespace', lang.schema.fullName.firstBackSpace, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .required('Số điện thoại không được để trống')
      .matches(phonenumberReg, lang.schema.phoneNumberInvalid),
    dob: yup
      .date()
      .min(MINDATE, `Năm sinh không được chọn trước năm ${MINDATE.getFullYear()}`)
      .max(subYears(new Date(), 18), 'Năm sinh phải đủ 18 tuổi')
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(lang.managementCustomer.dateValid),
    status: yup.number().nullable().required(lang.managementCustomer.statusRequired),
    email: yup
      .string()
      .trim()
      .nullable()
      .required(lang.managementCustomer.emailRequired)
      .max(60, lang.driverManagement.maxEmail)
      .test('username-is-email', lang.auth.emailvalid, function (value) {
        if (!value) return false;
        if (!googleEmailRegExp.test(value)) return false;

        const [username] = value.split('@');
        if (/^\d+$/.test(username)) {
          return this.createError({ message: 'Email không hợp lệ!' });
        }

        return true;
      }),
    address: yup.string().nullable().trim(),
    gender: yup.number().nullable(),
    avatar: yup
      .string()
      .nullable()
      .test('fileType', lang.driverManagement.imageFileType, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedFormats.includes(value.type)) {
          return createError({ path, message: lang.driverManagement.imageFileType });
        }
        return true;
      })
      .test('fileSize', lang.driverManagement.imageFileSize, function (value: any) {
        const { path, createError } = this as yup.TestContext;
        if (!value) {
          return true;
        }
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (value.size > maxSize) {
          return createError({ path, message: lang.driverManagement.imageFileSize });
        }
        return true;
      }),
  });
