import { Card, FormControl, Grid } from '@mui/material';
import { SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TableComponent from '@/components/TableComponent';
import { TRIPS_MANAGEMENT } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseTripManagementData } from '@/shared/constant/dataTable';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE_30 } from '@/shared/constant/common';
import {
  convertOptionsPhoneNumber,
  convertToYYYY,
  convertToYYYYMM,
  createNewPath,
  formatDate,
  useQueryParameters,
} from '@/shared/utils/common';
import TitlePage from '@/components/TitlePage';
import { tripStatus } from '@/shared/options';
import { ModalAssignDriver } from './ModalTrip/AssignDriver';
import { ModalCancelTrip } from './ModalTrip/CancelTrip';
import { schemaFormTrip } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { getExportFileTrip, getTrip, priceWaittingFee, tripData, updateStatus } from '@/redux/slices/trip';
import { driverData, getDropdownDriver } from '@/redux/slices/driver';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { customerData, getDropdownListCustomer } from '@/redux/slices/customer';
import { getPaymentSystemList, paymentSystemData } from '@/redux/slices/paymentSystem';
import { CustomDateField } from '@/components/CustomDateField';
import { regFileName } from '@/shared/utils/regex';
import { saveAs } from 'file-saver';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import { useRole } from '@/hooks/useRole';
import ModalEditPriceWaiting from './ModalEditPriceWaiting';
import GoogleMapAutoComplete from './TabsTripDetail/TripDetail/location';
import ModalPaid from './ModalPaid';

export default function TripManagement() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCancelTrip, setOpenCancelTrip] = useState<boolean>(false);
  const [openPaid, setOpenPaid] = useState<boolean>(false);
  const [id, setId] = useState<boolean>(false);
  const typeMonthYear = '2';
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const dataCustomer = useSelector(customerData);
  const dataDriver = useSelector(driverData);
  const dataPayment = useSelector(paymentSystemData);
  const navigate = useNavigate();
  const lang = window.lang;
  const queriesParams: any = useQueryParameters();
  const [queryFilter, setQueryfilter] = useState<any>({
    Page: queriesParams?.Page || 1,
    Take: PAGE_SIZE_30,
    DateType: typeMonthYear,
    MonthYear: convertToYYYYMM(new Date()),
  });
  const WAITTING_DRIVER = '7';
  const [priceWaiting, setPriceWaiting] = useState<boolean>(false);
  const [idTrip, setIdTrip] = useState<string>();
  const defaultValue = {
    Page: queriesParams?.Page || 1,
    Take: PAGE_SIZE_30,
    DateType: typeMonthYear,
    MonthYear: convertToYYYYMM(new Date()),
    Text: queriesParams?.text || '',
    Status: queriesParams?.status || '',
    DriverId: '',
    CustomerId: '',
    StatusTrip: '',
    PaymentSettingId: '',
    Quarter: '',
    Year: '',
    StartDate: '',
    EndDate: '',
  };
  const { pathname } = location;
  const router = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(+queriesParams?.Page || 1);
  const { role } = useRole();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaFormTrip(lang)),
    defaultValues: {
      driver: '',
      customer: '',
      status: '',
      payment: '',
      dateFilter: typeMonthYear,
      year: null,
      monthYear: new Date(),
      quarterYear: null,
      quarter: null,
      startDay: null,
      endDay: null,
    },
  });
  const dateFilter = watch('dateFilter');
  useEffect(() => {
    dispatch(getTrip(defaultValue));
    dispatch(getDropdownListCustomer());
    dispatch(getDropdownDriver());
    dispatch(getPaymentSystemList(''));
  }, []);

  const sendPrice = (id, value) => {
    const params = {
      tripId: id,
      waittingFee: +value,
    };
    const payload = {
      id,
      params: params,
      callbackSuccess: () => {
        showSuccessToast('Thêm giá chờ cho hành trình thành công!');
        setCurrentPage(+queriesParams?.Page || 1);
        dispatch(getTrip({ ...queryFilter, Page: +queriesParams?.Page, Take: PAGE_SIZE_30 }));
        dispatch(updateStatus());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(priceWaittingFee(payload));
  };

  const handleEditPriceWaiting = (idTrip) => {
    setPriceWaiting(true);
    setIdTrip(idTrip);
  };

  const onSubmit = (data) => {
    const dataSubmit = {
      DriverId: data.driver,
      CustomerId: data.customer,
      PaymentSettingId: data.payment,
      StatusTrip: data?.status === WAITTING_DRIVER ? 0 : data?.status,
      DateType: data.dateFilter === '0' ? null : data.dateFilter,
      StatisticType: data.typeProduct,
      Quarter: data.quarter,
      Year: convertToYYYY(data.year) || convertToYYYY(data.quarterYear),
      MonthYear: data.dateFilter === '2' ? convertToYYYYMM(data.monthYear) : null,
      StartDate: data.dateFilter === '1' ? formatDate(data.startDay) : null,
      EndDate: data.dateFilter === '1' ? formatDate(data.endDay) : null,
      Page: 1,
      Take: PAGE_SIZE_30,
    };
    setCurrentPage(1);
    setQueryfilter(dataSubmit);
    const { Page } = dataSubmit;
    const newPath = createNewPath(pathname, { Page });
    router(newPath);
    dispatch(getTrip(dataSubmit));
  };

  const handleResetFilter = () => {
    reset();
    setValue('monthYear', new Date());
    setCurrentPage(1);
    dispatch(
      getTrip({
        Page: 1,
        Take: PAGE_SIZE_30,
        DateType: typeMonthYear,
        MonthYear: convertToYYYYMM(new Date()),
      }),
    );
    setQueryfilter({
      Page: queriesParams?.Page || 1,
      Take: PAGE_SIZE_30,
      DateType: typeMonthYear,
      MonthYear: convertToYYYYMM(new Date()),
    });
    router(pathname);
  };

  const handleViewDetail = (id) => {
    navigate(CONST_ROUTER.TRIP_DETAIL1_ITEM(id));
  };

  const handleBlock = (id) => {
    setOpenCancelTrip(true);
    setTripId(id);
  };

  const handlePaid = (id) => {
    setOpenPaid(true);
    setId(id);
  };

  const handleAssignDriver = (id) => {
    setOpenModal(true);
    setTripId(id);
  };
  const dataTable = parseTripManagementData(
    dataSelector?.listTrip?.data,
    dataSelector?.listTrip?.totalCount,
    currentPage,
    handleAssignDriver,
    PAGE_SIZE_30,
    handleEditPriceWaiting,
    role,
  );

  const handleDownFile = () => {
    if (queryFilter) {
      const filteredQueryFilter = {};
      Object.keys(queryFilter).forEach((key) => {
        if (queryFilter[key] !== null && queryFilter[key] !== undefined && queryFilter[key] !== '') {
          filteredQueryFilter[key] = queryFilter[key];
        }
      });
    }
    const payload = {
      params: queryFilter,
      callbackSuccess: (response) => {
        const contentDispositionHeader = response.headers['content-disposition'];
        let filename = 'Trip.xlsx';

        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(regFileName);
          if (matches && matches.length > 1) {
            filename = matches[1];
          }
        }
        const blob = new Blob([response.data]);
        saveAs(blob, filename);
        showSuccessToast('Xuất file thành công!');
      },
      callbackError: (isError) => {
        showErrorToast(isError || 'Xuất file không thành công, vui lòng thử lại sau');
      },
    };
    dispatch(getExportFileTrip(payload));
  };

  const [tripId, setTripId] = useState<any>();

  useEffect(() => {
    if (dateFilter !== typeMonthYear) {
      setValue('year', null);
      setValue('quarterYear', null);
      setValue('quarter', null);
      setValue('startDay', null);
      setValue('endDay', null);
      clearErrors();
    }
  }, [dateFilter]);
  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      Page: page,
      Take: PAGE_SIZE_30,
    };
    const { Page } = data;
    const newPath = createNewPath(pathname, { Page });
    router(newPath);
    dispatch(getTrip(data));
  };

  const payloadSuccessAssign = () => {
    setOpenModal(false);
    showSuccessToast(lang.tripManagement.assignDriver);
    reset();
    setCurrentPage(+queriesParams?.Page || 1);
    dispatch(getTrip({ ...queryFilter, Page: +queriesParams?.Page, Take: PAGE_SIZE_30 }));
    dispatch(updateStatus());
  };

  const handleAddTrip = () => {
    navigate(CONST_ROUTER.ADD_TRIP);
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.tripManagement.pageTitle} />
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3.5}>
            <FormControl fullWidth>
              <SelectSearch
                name="driver"
                control={control}
                label="Tài xế"
                options={convertOptionsPhoneNumber(dataDriver.listDropdown || [])}
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3.5}>
            <FormControl fullWidth>
              <SelectSearch
                name="customer"
                control={control}
                label="Khách hàng"
                options={convertOptionsPhoneNumber(dataCustomer?.listDropdownCustomer || [])}
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <FormControl fullWidth>
              <SelectSearch
                name="payment"
                control={control}
                label="Hình thức thanh toán"
                options={convertOptionsPhoneNumber(dataPayment?.listPaymentSystem || [])}
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <FormControl fullWidth>
              <SelectSearch
                name="status"
                control={control}
                label="Trạng thái"
                options={tripStatus || []}
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CustomDateField dateFilter={dateFilter} control={control} errors={errors} />
          </Grid>
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          handleDownFile={handleDownFile}
          add={handleAddTrip}
        />
      </Card>

      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={TRIPS_MANAGEMENT}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={dataSelector?.listTrip?.data?.length}
          totalItems={dataSelector?.listTrip?.totalCount}
          onViewDetail={handleViewDetail}
          onBlock={handleBlock}
          onPaid={handlePaid}
          isLoading={dataSelector?.loadingListTrip}
          pagingCallback={(page) => handlePaging(page)}
          // onViewDetail={(id) => handleDetailGoods(id)}
          className="scroll-table"
          isPageSize={PAGE_SIZE_30}
        />
      </Grid>

      {openPaid && (
        <ModalPaid
          id={id}
          open={openPaid}
          setOpen={setOpenPaid}
          setCurrentPage={setCurrentPage}
          queriesParams={queriesParams}
          queryFilter={queryFilter}
        />
      )}

      {openModal && (
        <ModalAssignDriver
          tripId={tripId}
          setOpen={setOpenModal}
          open={openModal}
          setCurrentPage={setCurrentPage}
          queriesParams={queriesParams}
          queryFilter={queryFilter}
          payloadSuccessAssign={payloadSuccessAssign}
        />
      )}
      {openCancelTrip && (
        <ModalCancelTrip
          tripId={tripId}
          setOpen={setOpenCancelTrip}
          open={openCancelTrip}
          setCurrentPage={setCurrentPage}
          queriesParams={queriesParams}
          queryFilter={queryFilter}
        />
      )}
      {priceWaiting && (
        <ModalEditPriceWaiting
          open={priceWaiting}
          setOpen={setPriceWaiting}
          setCurrentPage={setCurrentPage}
          queriesParams={queriesParams}
          itemId={idTrip}
          queryFilter={queryFilter}
        />
      )}
      <CustomBackdrop open={dataSelector?.loadingExportFile} />
    </>
  );
}
