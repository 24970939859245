export const LOGIN_URL = '/api/auth/login';
export const USER_INFO = 'api/auth/info';
export const UPDATE_USER_INFO = 'api/auth/update-info';

export const LOGOUT_URL = '/api/auth/logout';
export const FORGET_PASS = '/api/auth/forgot-password';
export const CHANGE_PASSWORD = '/api/auth/change-password';

//Vehicle Class
export const VEHICLE_CLASS = '/api/management/vehicle-class/get';
export const DETAIL_VEHICLE_CLASS = (id) => `/api/management/vehicle-class/detail/${id}`;
export const POST_VEHICLE_CLASS = '/api/management/vehicle-class/create';
export const PUT_VEHICLE_CLASS = (id) => `/api/management/vehicle-class/update/${id}`;
export const DELETE_VEHICLE_CLASS = (id) => `/api/management/vehicle-class/delete/${id}`;

//Customer
export const CUSTOMER = 'api/management/customer/get';
export const LIST_VEHICLE_CUSTOMER = (id) => `api/management/customer/vehicle/${id}`;
export const DETAIL_CUSTOMER = `api/app/customer/detail`;
export const BLOCK_CUSTOMER = (id) => `api/management/customer/block/${id}`;
export const TRIP_HISTORY = `/Trip/trip-history`;
export const UN_BLOCK_CUSTOMER = (id) => `api/management/customer/unblock/${id}`;
export const EDIT_CUSTOMER = 'api/management/customer/update';
export const ADD_CUSTOMER = 'api/management/customer/create';
export const DROPDOWN_CUSTOMER = 'api/management/customer/dropdown';
export const DROPDOWN_CUSTOMER_AVAILABLE = 'api/management/customer/available';
export const DOWFILE_CUSTOMER = 'api/management/customer/export';
export const RESET_PASS_CUSTOMER = (id) => `api/auth/change-customer-password/${id}`;

//Driver
export const DRIVER = '/Driver';
export const ACTIVE_DRIVER = '/api/management/driver/active';
export const AVAILABLE_DRIVER = '/api/management/driver/available';
export const HISTORY_DRIVER = (id) => `/Driver/history/${id}`;
export const DROPDOWN_DRIVER = '/Driver/dropdown';
export const DETAIL_DRIVER = `/Driver/detail/`;
export const BLOCK_DRIVER = (id) => `/Driver/block/${id}`;
export const UN_BLOCK_DRIVER = (id) => `/Driver/unblock/${id}`;
export const DELETE_DRIVER = (id) => `/Driver/${id}`;
export const DOWFILE_DRIVER = '/Driver/export';
export const DRIVER_GOOGLE_MAP_UPDATE = '/api/management/driver/get-location-active-drivers';
export const RESET_DRIVER_PASSWORD = (id) => `/api/auth/reset-password/${id}`;

//Privinces
export const LIST_PROVINCES = '/api/management/price-system/provinces';

//Account
export const CREATE_ACCOUNT = '/api/management/employee/create';
export const GET_ACCOUNT = '/api/management/employee/all';
export const RESET_ACCOUNT_PASSWORD = (id) => `/api/auth/reset-password/${id}`;

export const DELTETE_ACCOUNT = (id) => `api/management/employee/delete/${id}`;

//Account
export const DETAIL_ACCOUNT = (id) => `/api/management/employee/detail/${id}`;
export const UPDATE_DETAIL_ACCOUNT = (id) => `/api/management/employee/update/${id}`;

//Discount
export const LIST_OFFER = '/api/management/discount';
export const DETAIL_OFFER = (id) => `/api/management/discount/${id}`;
export const PUT_OFFER = (id, isChangeImage) => `/api/management/discount/${id}/${isChangeImage}`;

//Trip
export const TEMPORARY_PRICE = `/Trip/temporary-distance-price`;
export const ASSIGN_DRIVER = `/Trip/assign-driver`;
export const WAITTING_FEE = (id) => `/Trip/admin-update-waiting-fee/${id}`;
export const EDIT_TRIP = (id) => `/Trip/admin-update-trip-locations/${id}`;
export const TRIP = `/Trip/trips`;
export const CANCEL_TRIP = `/Trip`;
export const DETAIL_TRIP = (id) => `/Trip/${id}`;
export const TRIP_DETAIL_HISTORY = (id) => `/Trip/log-trip/${id}`;
export const TRIP_DASHBOARD = '/Trip/dashboard';
export const DOWLOAD_FILE = '/Trip/export';
export const PAID = (id) => `/Trip/admin-confirmed-payment/${id}`;

//Price System
export const PRICE_SYSTEM = `/api/management/price-system`;
export const PRICE_SYSTEM_AREA = `/api/management/price-system/area`;
export const DELETE_PRICE_SYTEM = (id) => `/api/management/price-system//${id}`;
export const PRICE_SYSTEM_DETAIL = (id) => `/api/management/price-system/detail/${id}`;
export const ADD_PRICE_SYSTEM = `api/management/price-system`;

//payment System
export const PAYMENT_SYSTEM = `/payment-setting`;
export const PAYMENT_SYSTEM_DETAIL = (id) => `/payment-setting/detail/${id}`;
export const PAYMENT_STATUS = (id, isActive) => `/management/payment-setting/${id}/${isActive}`;

//Notification
export const NOTIFICATION = '/api/management/Notification';
export const READ_NOTIFICATION = (id) => `/api/management/Notification/read/${id}`;
export const READ_ALL_NOTIFICATION = '/api/management/Notification/mark-all-as-read';
//Statistic
export const CHART_REVENUE = '/api/management/statistic/chart-revenue';
export const TABLE_REVENUE = '/api/management/statistic/revenue';
export const CHART_TRIP = '/api/management/statistic/chart-trip';
export const TABLE_TRIP = '/api/management/statistic/trip';
export const PROVINCE_STATISTIC = '/api/management/statistic/province';
export const GET_TOTAL_EVALUATION = '/api/management/statistic/total-evaluation';
export const GET_LIST_EVALUATION = '/api/management/statistic/evaluation';
export const DOWNFILE_REVENUE = '/api/management/statistic/export-revenue';
export const DOWNFILE_TRIP = '/api/management/statistic/export-trip';
