import { CONTACT_INFO, GOSAFE_DRIVER } from '@/shared/constant/text';
import { Card } from '@mui/material';

const TermsOfUse = () => {
  const arrayMapList = [
    {
      title: '1. Chấp thuận điều khoản',
      dataSub: [
        `Khi đăng ký và sử dụng ứng dụng ${GOSAFE_DRIVER}, tài xế đồng ý tuân thủ đầy đủ các điều khoản và quy định của chúng tôi.`,
        'Các điều khoản này có thể thay đổi và cập nhật theo thời gian mà không cần thông báo trước.',
      ],
    },
    {
      title: '2. Yêu cầu đối với tài xế',
      dataSub: [
        'Tài xế phải có bằng lái xe hợp lệ và phù hợp với loại xe được phép điều khiển theo quy định của pháp luật.',
        `Tài xế phải đảm bảo có đầy đủ sức khỏe và năng lực hành nghề khi sử dụng dịch vụ lái xe hộ của ${GOSAFE_DRIVER}.`,
        'Tài xế cam kết không sử dụng rượu, bia, hoặc các chất kích thích khác khi lái xe cho khách hàng.',
      ],
    },
    {
      title: '3. Phạm vi trách nhiệm',
      dataSub: [
        `${GOSAFE_DRIVER} chỉ cung cấp nền tảng kết nối giữa tài xế và khách hàng, không chịu trách nhiệm trực tiếp về các vấn đề xảy ra giữa tài xế và khách hàng trong quá trình cung cấp dịch vụ.`,
        'Tài xế chịu trách nhiệm pháp lý đối với các hành vi của mình khi tham gia giao thông và cung cấp dịch vụ cho khách hàng.',
      ],
    },
    {
      title: '4. Quy định về hành vi',
      dataSub: [
        'Tài xế phải đảm bảo thái độ lịch sự, chuyên nghiệp khi phục vụ khách hàng.',
        'Không quấy rối, xâm phạm quyền riêng tư, hoặc có các hành vi thiếu tôn trọng đối với khách hàng.',
      ],
    },
    {
      title: '5. Bảo mật thông tin',
      dataSub: [
        'Tài xế không được chia sẻ hoặc sử dụng trái phép thông tin cá nhân của khách hàng cho bất kỳ mục đích nào ngoài việc cung cấp dịch vụ lái xe hộ.',
      ],
    },
    {
      title: '6. Hình thức thanh toán',
      dataSub: [
        `Tài xế đồng ý nhận thanh toán qua các phương thức mà ${GOSAFE_DRIVER} cung cấp.`,
        `${GOSAFE_DRIVER} sẽ không chịu trách nhiệm nếu tài xế không tuân thủ các quy trình thanh toán do nền tảng yêu cầu.`,
      ],
    },
    {
      title: '7. Hủy dịch vụ',
      dataSub: [
        `Tài xế có quyền từ chối hoặc hủy dịch vụ trong các trường hợp có lý do hợp lý, nhưng phải thông báo trước cho khách hàng và ${GOSAFE_DRIVER}.`,
      ],
    },
    {
      title: '8. Hình thức xử lý vi phạm',
      dataSub: [
        `Trong trường hợp tài xế vi phạm các điều khoản sử dụng, ${GOSAFE_DRIVER} có quyền đình chỉ hoặc chấm dứt tài khoản của tài xế mà không cần thông báo trước.`,
      ],
    },
    {
      title: '9. Bồi thường thiệt hại',
      dataSub: [
        `Tài xế đồng ý bồi thường cho khách hàng hoặc ${GOSAFE_DRIVER} trong trường hợp gây ra thiệt hại về tài sản hoặc sức khỏe trong quá trình cung cấp dịch vụ.`,
      ],
    },
    {
      title: '10. Giải quyết tranh chấp',
      dataSub: [
        `Trong trường hợp xảy ra tranh chấp giữa tài xế và khách hàng, ${GOSAFE_DRIVER} sẽ hỗ trợ các bên hòa giải nhưng không chịu trách nhiệm pháp lý về kết quả của quá trình này.`,
      ],
    },
  ];

  const InfoItem = ({ label, children }) => {
    return (
      <span>
        <strong>{label}</strong>: {children}
      </span>
    );
  };

  const FooterPrivacy = ({ contactInfo }) => {
    return (
      <div className="footer-privacy">
        <span>{contactInfo.textRole} có thể gửi khiếu nại tại địa chỉ:</span> <br />
        <strong>Gosafe – Dịch vụ lái xe hộ uy tín trên toàn quốc</strong> <br />
        <InfoItem label="Địa chỉ">{contactInfo.address}</InfoItem>
        <br />
        <InfoItem label="Email">
          <a href={`mailto:${contactInfo.email}`}>
            <span>{contactInfo.email}</span>
          </a>
        </InfoItem>
        <br />
        <InfoItem label="Điện thoại">{contactInfo.phone}</InfoItem>
        <br />
        <InfoItem label="Giờ làm việc">{contactInfo.workingHours}</InfoItem>
      </div>
    );
  };

  return (
    <>
      <Card className="width-privacy">
        <h1 className="title-privacy">
          <strong>ĐIỀU KHOẢN SỬ DỤNG</strong>
        </h1>
        {arrayMapList?.map((item) => {
          return (
            <>
              <div>
                <h2>
                  <strong> {item?.title}</strong>
                </h2>
              </div>
              {item?.dataSub?.map((item1, index1) => {
                return (
                  <>
                    <ul key={index1}>
                      <li>{item1}</li>
                    </ul>
                  </>
                );
              })}
            </>
          );
        })}
        <FooterPrivacy contactInfo={CONTACT_INFO} />
      </Card>
    </>
  );
};

export default TermsOfUse;
