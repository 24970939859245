import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailTrip, getTrip, postPaid, putCancelTrip, tripData, updateStatus } from '@/redux/slices/trip';
import { allowedTypes, dataAddress, formatCurrency, formatDateTimeNotUTC, isBooleanArray } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { PAGE_SIZE_30 } from '@/shared/constant/common';
import LoadingPage from '@/components/LoadingPage';
import { MAX_LENGTH, UNKNOW } from '@/shared/constant/text';
import CustomImage from '@/components/CustomImage';
import MediaDisplay from '@/components/MediaDisplay';

const schema = yup.object().shape({
  image: yup.string().nullable(),
});

const ModalPaid = ({ open, setOpen, id, setCurrentPage, queriesParams, queryFilter }) => {
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const dataDetail = dataSelector?.tripDetail?.data;
  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;
  const { loadingTripDetail, loadingCancelTrip, loadingPostPaid } = dataSelector;

  const [imgPath, setImgPath] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const gridItems = [
    { label: 'Ngày đặt', value: formatDateTimeNotUTC(dataDetail?.createdDate) },
    { label: 'Ngày xuất phát', value: formatDateTimeNotUTC(dataDetail?.startTime) },
    { label: 'Tên tài xế', value: dataDetail?.driverName },
    { label: 'Tên khách hàng', value: dataDetail?.customerName },
    { label: 'Điểm đi', value: dataAddress(dataDetail?.tripLocations, StartingPoint) },
    { label: 'Điểm dừng', value: dataAddress(dataDetail?.tripLocations, StoppingPoint), isAddressStop: true },
    {
      label: 'Điểm đến',
      value: isBooleanArray(dataAddress(dataDetail?.tripLocations, DestinationPoint))
        ? dataAddress(dataDetail?.tripLocations, DestinationPoint)
        : UNKNOW,
    },
    { label: 'Tổng quãng đường', value: `${dataDetail?.distance} Km` },
    { label: 'Tạm tính ', value: formatCurrency(dataDetail?.price) },
    { label: 'Phí chờ ', value: formatCurrency(dataDetail?.waittingFee) },
    { label: 'Phí phụ thu ', value: formatCurrency(dataDetail?.surcharge) },
    { label: 'Khuyến mãi ', value: formatCurrency(dataDetail?.amountDiscounted || 0) },
    { label: 'Tổng tiền ', value: formatCurrency(dataDetail?.lastPrice) },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getDetailTrip(id));
    }
  }, [id]);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: '',
    },
  });

  const handleClose = () => setOpen(false);

  const handlePaid = (data) => {
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(`PaymentImages`, selectedFiles[i]);
    }

    const payload = {
      id,
      params: formData,
      callbackSuccess: () => {
        reset();
        showSuccessToast('Thanh toán hành trình thành công!');
        setCurrentPage(+queriesParams?.Page || 1);
        dispatch(getTrip({ ...queryFilter, Page: +queriesParams?.Page, Take: PAGE_SIZE_30 }));
        dispatch(updateStatus());
        handleClose();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postPaid(payload));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newSelectedFiles = selectedFiles.slice();
    const newImgPath = imgPath.slice();
    let remainingImages = MAX_LENGTH - selectedFiles.length;
    for (let i = 0; i < files.length; i++) {
      if (remainingImages <= 0) {
        showErrorToast('Bạn chỉ có thể tải lên tối đa 5 hình ảnh');
        break;
      }
      const file = files[i];
      if (!allowedTypes.includes(file.type)) {
        showErrorToast('Hình ảnh không hợp lệ');
      } else {
        const imgUrl = URL.createObjectURL(file);
        newImgPath.push(imgUrl);
        newSelectedFiles.push(file);
        remainingImages--;
      }
    }
    setImgPath(newImgPath);
    setSelectedFiles(newSelectedFiles);
  };

  const handleRemoveImage = (index) => {
    const newSelectedFiles = selectedFiles.slice();
    const newImgPath = imgPath.slice();
    newSelectedFiles.splice(index, 1);
    newImgPath.splice(index, 1);
    setImgPath(newImgPath);
    setSelectedFiles(newSelectedFiles);
    if (imgPath.length < 2) {
      setValue('image', '');
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        PaperProps={{
          style: {
            width: '100%',
            maxHeight: '100%',
          },
        }}
      >
        {loadingPostPaid && <CustomBackdrop open={loadingPostPaid} />}
        <DialogTitle className="popup-title">
          <b>Xác nhận thanh toán hành trình {dataDetail?.code}</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        <DialogContent className="modal-dialog-trip">
          {loadingTripDetail ? (
            <LoadingPage />
          ) : (
            <Grid container className="dialog-content-trip" gap={1}>
              <Grid container spacing={1}>
                {gridItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={5}>
                      <strong>{item.label}: </strong>
                    </Grid>
                    <Grid item xs={7}>
                      <div>{item?.value}</div>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>

              <Grid item xs={12} display="flex" alignItems="center" mt={2}>
                <FormControl fullWidth>
                  <label className="add-img" htmlFor="fileInput">
                    <Typography className="add-img-text">Thêm hình ảnh thanh toán</Typography>
                  </label>
                  {errors?.image && (
                    <FormHelperText error sx={{ marginLeft: '14px' }}>
                      {errors?.image?.message}
                    </FormHelperText>
                  )}

                  <Controller
                    name="image"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          className="image-hidden"
                          {...field}
                          inputProps={{
                            accept: 'image/*',
                            multiple: true,
                          }}
                          id="fileInput"
                          type="file"
                          onChange={(event) => {
                            handleFileChange(event);
                            field.onChange(event);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="justify-center" alignContent="center" spacing={2}>
                  <>
                    {selectedFiles.length > 0 && (
                      <>
                        {imgPath.map((file, index) => (
                          <Grid item md={6} key={index}>
                            <MediaDisplay
                              src={file}
                              alt={`image_${index}`}
                              className="image-paymend-spend"
                              onDelete={() => handleRemoveImage(index)}
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  </>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-button-action">
          <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
            <ButtonCustom fullWidth={false} color="error" onClick={handleClose} title={lang.title.titlePage.close} />
            <ButtonCustom
              fullWidth={false}
              color="primary"
              onClick={handleSubmit(handlePaid)}
              title={lang.title.titlePage.confirm}
            />
          </Grid>
        </DialogActions>
        <CustomBackdrop open={loadingCancelTrip} />
      </Dialog>
    </>
  );
};

export default ModalPaid;
